import { api_server_url } from '../utils/constants';
import { useState, useEffect } from 'react';

const SessionSelection = ({ sessionId, onSessionSelect, googleAuthToken }) => {
    const [completedSessions, setCompletedSessions] = useState([]);
    useEffect(() => {
        fetch(`${api_server_url}/admin/get_completed_sessions?limit=100`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Authorization': `Bearer ${googleAuthToken}`
            }
        })
            .then(response => response.json())
            .then(data => setCompletedSessions(data));
    }, [googleAuthToken]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2>Select a session</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', fontSize: '18px' }}>
                <div>
                    <button onClick={() => {
                        const currentIndex = completedSessions.indexOf(sessionId);
                        if (currentIndex > 0) {
                            onSessionSelect(completedSessions[currentIndex - 1]);
                        }
                    }}>Previous Session</button>
                </div>
                <div>
                    <input
                        type="text"
                        value={sessionId}
                        onChange={(e) => onSessionSelect(e.target.value)}
                        list="sessionsList"
                        style={{ width: '30vw', height: '40px', fontSize: '18px' }}
                    />
                    <datalist id="sessionsList">
                        {completedSessions && Array.isArray(completedSessions) && completedSessions.map((session, index) => (
                            <option key={index} value={session} style={{ fontSize: '18px' }}>
                                {session}
                            </option>
                        ))}
                    </datalist>
                </div>
                <div>
                    <button onClick={() => {
                        const currentIndex = completedSessions.indexOf(sessionId);
                        if (currentIndex < completedSessions.length - 1) {
                            onSessionSelect(completedSessions[currentIndex + 1]);
                        }
                    }}>Next Session</button>
                </div>
            </div>
        </div >
    )
}

export default SessionSelection;