import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation, Autoplay, Keyboard } from 'swiper/modules';
import { useState, useEffect, useRef } from 'react';
import useWindowSize from '../utils/useWindowSize';
import SigninMethodsWidget from '../signup/SigninMethodsWidget';
import useServerConfig from '../utils/useServerConfig';
import Loader from 'react-loaders'
import Confetti from 'react-confetti';
import mixpanel from 'mixpanel-browser';
import "loaders.css"
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import './GeneratedImages.css';
import { getResizedImagesUrls, getImagesRating } from '../utils/jsUtils';
import Cookies from 'js-cookie';
import StripeCheckoutButton from '../pay/StripeCheckoutButton';
import SeeMoreImagesButton from '../pay/SeeMoreImagesButton';
import { api_server_url } from '../utils/constants';
import { datadogLogs } from '@datadog/browser-logs'

const getGeneratedImagesEndpoint = "/get_session_generated_images"

const GeneratedImages = () => {
  const [sessionImageUrls, setSessionImageUrls] = useState([]);
  const [displayedImagesIds, setDisplayedImagesIds] = useState([]);
  const [width, height] = useWindowSize();
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showAmazingText, setShowAmazingText] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = window.innerWidth <= 500;
  const config = useServerConfig();
  const [wasTouched, setWasTouched] = useState(false);
  const [imagesRating, setImagesRating] = useState({});
  const [sessionId, setSessionId] = useState('');
  const [payed, setPayed] = useState(false);
  const imagesPerPage = config?.generated_images_page?.nof_images_pre_page;
  const swiperRef = useRef(null);
  const paginatedImageUrls = sessionImageUrls.slice((currentPage - 1) * imagesPerPage, currentPage * imagesPerPage);
  const nofNonBlurredImages = config?.generated_images_page?.nof_non_blurred_images;

  useEffect(() => {
    // Extract session_id from URL
    const urlParams = new URLSearchParams(window.location.search);
    const sessionFromUrl = urlParams.get('session_id');
    const payedFromUrl = urlParams.get('payed');
    if (sessionFromUrl) {
      setSessionId(sessionFromUrl);
      // if the session_id cookie is set to this session - delete it
      // so the user can have another go
      if (Cookies.get('session_id') === sessionFromUrl) {
        Cookies.remove('session_id');
      }
    }
    if (payedFromUrl) {
      // if it comes from url, then the images won't be displayed until checkout completes
      setPayed(payedFromUrl === 'true');
    }
    fetch(`${api_server_url}/update_visited_generated_page_endpoint?session_id=${sessionFromUrl}`, {
      method: 'POST',
      redirect: 'follow',
    })
      .catch((error) => {
        datadogLogs.logger.error('Error while listing visited generated page:', { 'error-message': error.message, 'hue-session-id': sessionFromUrl });
      });
  }, []);

  useEffect(() => {
    const fetchImages = () => {
      // if payed is set from URL, this endpoint will not return any images until checkout completes
      // on mobile, resize to 1.2 aspect ratio to not zoom in too much
      // on desktop - use the generated image size * 1.5
      const generatedImageHeight = config?.training?.train_image_h;
      const generatedImageWidth = config?.training?.train_image_w;
      let queryWidth = isMobile ? parseInt(width) : parseInt(generatedImageWidth * 1.5);
      let queryHeight = isMobile ? parseInt(queryWidth * 1.20) : parseInt(generatedImageHeight * 1.5);
      getResizedImagesUrls(sessionId, getGeneratedImagesEndpoint, queryWidth, queryHeight, null, payed).then((urls) => {
        if (urls && urls.session_images_urls && urls.session_images_urls.length > 0) {
          setSessionImageUrls(urls.session_images_urls);
          setDisplayedImagesIds(urls.displayed_images_ids);
          getImagesRating(urls.displayed_images_ids).then((ratings) => {
            setImagesRating(ratings);
          });
          if (urls.user_payed) {
            // this is so when a payed user visits the page again it will show him all the images
            setPayed(true);
          }
          setImagesLoaded(0);
        } else if (payed) {
          // this means that the payed state comes from url (not server)
          // and we since the server did not return any images we must 
          // wait until stripe confirms the checkout finished
          setTimeout(fetchImages, 1000); // Retry after 1 second
        }
      });
    };

    if (sessionId && width && height && config && Object.keys(config).length > 0) {
      fetchImages();
    }
  }, [sessionId, width, height, isMobile, config, payed]);

  const handleImageLoad = () => {
    // count loaded images
    setImagesLoaded(prev => prev + 1);
  };

  useEffect(() => {
    // Check if all images have loaded
    const SHOW_TEXT_TIMEOUT = 3000; // 3 sec
    const nofImagesToLoad = Math.min(imagesPerPage, sessionImageUrls.length);
    if (sessionImageUrls && imagesLoaded === nofImagesToLoad && sessionImageUrls.length > 0) {
      setIsLoading(false);
      // show amazing you text
      setShowAmazingText(true);
      setTimeout(() => setShowAmazingText(false), SHOW_TEXT_TIMEOUT);
    }
  }, [imagesLoaded, sessionImageUrls, imagesPerPage]);

  const onSlideChange = (swiper) => {
    setWasTouched(true);
    const activeSlide = swiper.activeIndex + 1;
    mixpanel.track("Moved slide", {
      "Session ID": sessionId,
      "Active slide": activeSlide,
      "Active Page": currentPage
    });
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (swiperRef.current) {
      if (newPage > currentPage) {
        swiperRef.current.swiper.slideTo(0); // Scroll to the first slide
      } else {
        swiperRef.current.swiper.slideTo(imagesPerPage - 1); // Scroll to the last slide
      }
    }
  };

  const handleImageContextMenu = (imageUrl, index, event) => {
    event.preventDefault();
    mixpanel.track("Image Context Menu", {
      "Session ID": sessionId,
      "Image URL": imageUrl,
      "Image Index": index,
      "Current Page": currentPage
    });
  };

  return (
    <div name="generated-images" style={
      {
        backgroundColor: '#101215',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div style={{ height: '90vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Swiper
          ref={swiperRef}
          modules={[EffectCards, Autoplay, ...(isMobile ? [] : [Navigation, Keyboard])]}
          effect="cards"
          style={{ width: '90vw', height: '85vh', opacity: isLoading ? 0 : 1, '--swiper-navigation-size': isMobile ? '0px' : '44px', marginTop: isMobile ? '60px' : '0px' }}
          navigation={{
            enabled: !isMobile,
          }}
          keyboard={{ enabled: true }}
          onSlideChange={onSlideChange}
          onTouchStart={() => setWasTouched(true)}
          lazyPreloadPrevNext={5}
        >
          {paginatedImageUrls && Array.isArray(paginatedImageUrls) && paginatedImageUrls.map((imageUrl, index) => (
            <SwiperSlide key={index} className={index === 0 && !wasTouched ? 'wiggle-animation' : ''}>
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img
                  src={imageUrl}
                  alt={`Generated ${index}`}
                  onLoad={handleImageLoad}
                  onContextMenu={(event) => handleImageContextMenu(imageUrl, index, event)}
                />
                <div style={
                  {
                    position: 'absolute',
                    top: isMobile ? '20%' : '70%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontSize: '2rem',
                    width: isMobile ? '90%' : 'auto',
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}>
                  {/*Amazing You. text displays for 3 sec*/}
                  {showAmazingText &&
                    <span style={{ color: 'white' }}>Amazing you.</span>}
                  {/*Image rating displays after 3 sec */}
                  {!showAmazingText && imagesRating && Object.keys(imagesRating).length > 0 &&
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: '10px',
                    }}>
                      {/*Pay button */}
                      <div >
                        {((index >= nofNonBlurredImages || currentPage > 1) && !payed) ? (
                          <SeeMoreImagesButton sessionId={sessionId} />
                        ) : (
                          <StripeCheckoutButton sessionId={sessionId} image_id={displayedImagesIds[(currentPage - 1) * imagesPerPage + index]} image_url={imageUrl} />
                        )}
                      </div>
                    </div>}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/*Loader */}
        {isLoading && (
          <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0 }}>
            <Loader type="line-scale-party" active />
          </div>)
        }
        {/*Confetti */}
        {!isLoading && <Confetti recycle={false} numberOfPieces={1000} gravity={0.04} />}
      </div>

      {sessionImageUrls && sessionImageUrls.length > 0 && imagesPerPage &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: isMobile ? '90px' : '30px' }}>
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}
            style={{
              margin: '0 5px', padding: '10px 10px', borderRadius: '5px', backgroundColor: 'transparent', color: 'white',
              border: '1px solid white', cursor: 'pointer', width: isMobile ? '70px' : '80px', fontWeight: 'bold'
            }}>
            Previous
          </button>
          <span style={{ margin: '0 10px', color: 'white' }}>
            {`Viewing ${((currentPage - 1) * imagesPerPage) + 1}-${Math.min(currentPage * imagesPerPage, sessionImageUrls.length)} of ${sessionImageUrls.length}`}
          </span>
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * imagesPerPage >= sessionImageUrls.length}
            style={{
              margin: '0 5px', padding: '10px 20px', borderRadius: '5px', backgroundColor: 'transparent', color: 'white',
              border: '1px solid white', cursor: 'pointer', width: isMobile ? '70px' : '80px', fontWeight: 'bold'
            }}>
            Next
          </button>
        </div>
      }
      <SigninMethodsWidget sessionId={sessionId} />
    </div >
  );
}

export default GeneratedImages