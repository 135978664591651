import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'primereact/button';
import './ChooseSelfieModal.css';
import { uploadToCloudinary } from '../../../../../utils/jsUtils';
import Loader from 'react-loaders';
import { api_server_url } from '../../../../../utils/constants';

const ChooseSelfieModal = ({ isOpen, onRequestClose, setUserUploadedSelfie, sessionId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSelfieValid, setIsSelfieValid] = useState(true);

    const checkSelfie = async (imageUrl) => {
        const response = await fetch(`${api_server_url}/check_user_selfie?image_url=${imageUrl}`, {
            method: 'POST',
        });
        return response.json();
    };
    const handleUpload = () => {
        // Simulate the upload process
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) => {
            setIsLoading(true);
            const file = event.target.files[0];
            if (file) {
                // onRequestClose();
                uploadToCloudinary(file, sessionId).then((data) => {
                    checkSelfie(data['url']).then((result) => {
                        if (result) {
                            onRequestClose();
                            setUserUploadedSelfie(data['url']);
                            setIsSelfieValid(true);
                        }
                        else {
                            setIsLoading(false);
                            setIsSelfieValid(false);
                        }
                    });
                })
            }
        };
        input.click();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Selfie modal"
            appElement={document.getElementById('root')}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    border: '1px solid #ccc',
                    background: '#101215',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '10px',
                    outline: 'none',
                    padding: '20px'
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    {isSelfieValid ? <h2>Upload a selfie</h2> : <h2>Invalid selfie</h2>}
                    {isSelfieValid ? <p>We also need a selfie, so we can pick photos<br /> of you from your Google Photos / Facebook</p> :
                        <p>Your selfie should not contain other people, <br /> be well lit, and have clear sight of your face</p>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', width: '90%' }}>
                    {isLoading ? <Loader type="ball-pulse" active /> :
                        <Button icon="pi pi-images" label="Upload From Device" style={{ width: '100%' }} outlined className="media-button" onClick={handleUpload} />}
                </div>
            </div>
        </Modal>
    );
};

export default ChooseSelfieModal;
