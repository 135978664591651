function Footer() {
    const isMobile = window.innerWidth <= 500;
    return (
        <div style={{ textAlign: 'center', color: 'white', background: '#101215', position: 'relative', fontSize: 'calc(8px + 1.5vmin)' }}>
            <hr style={{ width: '99%', borderTop: '1.5px solid #bbb', borderRadius: '0', opacity: '0.3', margin: '0' }} />
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                {!isMobile && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="./logo.svg" alt="logo" style={{ width: '120px', height: 'auto', marginTop: '20px' }} />
                </div> }
                {!isMobile && <div style={{ width: '3vw' }}></div>}
                <div style ={{marginRight: isMobile ? '0px' : '3vw'}}>
                    <div style={{ height: '2.5vh'}}></div>
                    Need help? 🤔 Contact our <a href="mailto:support@eikona.io" style={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">support mail</a>,
                    {isMobile && <br />} we answer within 12 hours. {isMobile && <br />} Please review our <a href="/privacy-policy" style={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/terms-of-service" style={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                </div>
            </div>
            <div style={{ height: '3vh' }}></div>
        </div>
    )
}

export default Footer;