import { api_server_url } from "../utils/constants";
import { setImageRating } from "../utils/jsUtils";
import mixpanel from 'mixpanel-browser';

const StripeCheckoutButton = ({ sessionId, image_id, image_url }) => {
    const handleButtonClick = () => {
        setImageRating(image_id, 5);
        mixpanel.track("Checkout button clicked", {
            "Session ID": sessionId,
            "Image ID": image_id,
            "Image URL": image_url
        });
    };

    return <div style={{
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'white',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    }}>
        <form action={`${api_server_url}/stripe/create-checkout-session?session_id=${sessionId}&image_id=${image_id}`} method="POST">
            <button
                type="submit"
                style={{
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    border: '1px solid #ffffff',
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: '18px',
                    padding: '10px 10px',
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}
                onClick={handleButtonClick}
            >Remove Watermark</button> &nbsp;
        </form>
    </div>
};

export default StripeCheckoutButton;
