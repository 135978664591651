import { api_server_url } from "./constants";
import { datadogLogs } from '@datadog/browser-logs'

const cloudName = 'dxgcobmaz';
const uploadPreset = 'wjgzik8l';
const user_photos_prefix = `user_photos`
const raw_images_dir = `raw/`

export const getSessionStatus = (sessionId) => {
    return fetch(`${api_server_url}/get_session_status?session_id=${sessionId}`, {
        method: 'POST',
        redirect: 'follow',
    })
        .then(response => response.json())
        .catch((error) => {
            datadogLogs.logger.error('Error while getting session status:', { 'error-message': error.message, 'hue-session-id': sessionId });
        });
}

export const getUserMailBySessionId = (sessionId) => {
    return fetch(`${api_server_url}/get_user_email_from_session_id_endpoint?session_id=${sessionId}`, {
        method: 'POST',
        redirect: 'follow',
    })
        .then(response => response.json())
        .catch((error) => {
            datadogLogs.logger.error('Error while getting user email:', { 'error-message': error.message, 'hue-session-id': sessionId });
        });
}

export const getResizedImagesUrls = (sessionId, enpoint, queryWidth, queryHeight, googleAuthToken = null, onlyOnPayed = false) => {
    let queryParams = `&width=${queryWidth}&height=${queryHeight}`;
    if (onlyOnPayed){
        queryParams += '&only_on_payed=true'
    }
    const headers = googleAuthToken ? {
        'Authorization': `Bearer ${googleAuthToken}`
    } : {};
    console.log('headers', headers);
    return fetch(`${api_server_url}${enpoint}?session_id=${sessionId}${queryParams}`, {
        method: 'POST',
        redirect: 'follow',
        headers: headers
    })
        .then(response => response.json())
        .catch((error) => {
            console.error('Error:', error);
        });
}

export const getImagesRating = (sessionGeneratedImagesIds) => {
    return fetch(`${api_server_url}/get_images_rating`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image_ids: sessionGeneratedImagesIds })
    }).then((response) => response.json())
        .catch((error) => {
            console.log(error);
        });
}

export const convertToJpeg = (file) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
                resolve(new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: 'image/jpeg' }));
            }, 'image/jpeg', 0.9);
        };
        img.onerror = (error) => reject(error);
    });
};

export const setImageRating = (imageId, value) => {
    fetch(`${api_server_url}/set_image_rating?image_id=${imageId}&rating=${value}`, {
        method: "POST",
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.log(error);
    });
}


export async function uploadToCloudinary(file, sessionId) {
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
    // Convert PNG to JPEG if necessary
    if (file.type === 'image/png') {
        file = await convertToJpeg(file);
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('folder', `${user_photos_prefix}/${sessionId}/${raw_images_dir}`);

    return fetch(url, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
}

export const uploadImagesEndpoint = (sessionId, imageUrlsToUpload, selfieUrl = null) => {
    const requestBody = {
        session_id: sessionId,
        image_urls: imageUrlsToUpload
    };

    if (selfieUrl) {
        requestBody.selfie_url = selfieUrl;
    }

    fetch(`${api_server_url}/upload_images/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        redirect: 'follow',
    })
        .catch((error) => {
            datadogLogs.logger.error('Error while uploading user photos', { 'error-message': error.message, 'hue-session-id': sessionId });
        });
}

export const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    return emailRegex.test(email);
}
