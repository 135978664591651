import React from 'react';
import './Blog.css'; // Ensure you have this CSS file in the same directory

function BlogPage() {
  return (
    <div className="blog-container">
      <iframe src="/blog.html" title="Blog" />
    </div>
  );
}

export default BlogPage;