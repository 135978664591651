import Loader from 'react-loaders'
import "loaders.css"
import { useEffect, useState, useRef } from 'react';
import { datadogLogs } from '@datadog/browser-logs'
import { useNavigate } from 'react-router-dom';
import { getSessionStatus } from '../../../../utils/jsUtils';
import { Toast } from 'primereact/toast';
import Modal from 'react-modal';
import Cookies from 'js-cookie';

const Stage = {
    CRASHED: 'crashed',
    CREATED: 'created',
    UPLOADING_IMAGES: 'uploading_images',
    PRE_PROCESSING: 'pre_processing',
    TRAINING: 'training',
    GENERATING_IMAGES: 'generating_images',
    SCORING_IMAGES: 'scoring_images',
    RANKING_IMAGES: 'ranking_images',
    UPLOADING_TO_CDN: 'uploading_to_cdn',
    COMPLETED: 'completed'
};


class JobStatusTracker {
    constructor() {
        this.currentStage = Stage.CREATED;
        this.lastStageUpdateTime = Math.floor(Date.now() / 1000);
        this.stagesStatusMap = {
            [Stage.CREATED]: ["Polling training server"],
            [Stage.UPLOADING_IMAGES]: [
                "Uploading images",
                "Picking your images",
                "Removing duplicates",
                "Choosing the best images"
            ],
            [Stage.PRE_PROCESSING]: [
                "Removing low quality images",
                "Removing similar images",
                "Extracting faces",
                "Removing obstructed faces",
                "Discovering identities"
            ],
            [Stage.TRAINING]: [
                "Learning facial features",
                "Learning clothing style",
                "Learning expressions",
                "Learning context",
                "Training neural net",
                "Fine tuning LoRAs"
            ],
            [Stage.GENERATING_IMAGES]: [
                "Engine1: Generating images",
                "Engine2: Generating images",
                "Engine3: Generating images"
            ],
            [Stage.SCORING_IMAGES]: [
                "Scoring results",
                "Applying HUE algorithm",
                "Discarding unsimilar images",
                "Extending images",
                "Applying super resolution"
            ],
            [Stage.RANKING_IMAGES]: [
                "Ranking results",
                "Chossing best images"
            ],
            [Stage.UPLOADING_TO_CDN]: [
                "Resizing images",
                "Uploading images to CDN",
            ],
            [Stage.COMPLETED]: ["Delivering images"],
            [Stage.CRASHED]: ["Error :-\\"]
        };
        this.stageIntervalMap = new Map([
            ['created', 0 + Math.random() * 5],
            ['uploading_images', 120 + Math.random() * 30],
            ['pre_processing', 60 * 1.5 + Math.random() * 7],
            ['training', 60 * 10 + Math.random() * 20],
            ['generating_images', 60 * 8 + Math.random() * 10],
            ['scoring_images', 60 * 3 + Math.random() * 10],
            ['ranking_images', 60 * 1.5 + Math.random() * 10],
            ['uploading_to_cdn', 60 * 2 + Math.random() * 10],
            ['completed', 60 * 0.1 + Math.random() * 5],
            ['crashed', 1],
        ]);
    }
    resetLastStageUpdateTime() {
        this.lastStageUpdateTime = Math.floor(Date.now() / 1000);
    }
    getRemainingTime() {
        let found = false;
        const remainingStagesTimeArray = [];
        for (const [currentKey, value] of this.stageIntervalMap.entries()) {
            if (found) {
                remainingStagesTimeArray.push(value);
            }
            if (currentKey === this.currentStage) {
                found = true;
            }
        }
        const stagesAccumulatedTime = remainingStagesTimeArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        const timeDelta = Math.floor(Date.now() / 1000) - this.lastStageUpdateTime;
        const remainingTimeCurrentStage = Math.max(0, this.stageIntervalMap.get(this.currentStage) - timeDelta);
        const secondsLeft = stagesAccumulatedTime + remainingTimeCurrentStage;
        return Math.max(1, Math.floor(secondsLeft / 60));
    }
    getCurrentStatus() {
        const timeDelta = Math.floor(Date.now() / 1000) - this.lastStageUpdateTime;
        const numCurrentStatuses = this.stagesStatusMap[this.currentStage].length;
        const stageTimeInterval = this.stageIntervalMap.get(this.currentStage) / numCurrentStatuses;
        const statusIdx = Math.min(numCurrentStatuses - 1, Math.floor(timeDelta / stageTimeInterval));
        const displyTextWordList = this.stagesStatusMap[this.currentStage][statusIdx].split(' ');
        const remainingRunTime = this.getRemainingTime();
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <span>
                    <span style={{ color: 'gold' }}>{displyTextWordList[0]}</span> {displyTextWordList.slice(1).join(' ')}
                </span>
                <span style={{ display: 'flex', alignItems: 'center', marginLeft: '2.5px' }}>
                    <img src="./clock.png" alt="Clock" style={{
                        width: '20px', marginRight: '5px', filter: 'invert(100%)'
                    }} />
                    <span>{remainingRunTime} minutes</span>
                </span>
            </div>
        );
    }
    setCurrentStage(stage) {
        const isStageValid = Object.values(Stage).includes(stage);
        if (!isStageValid) {
            datadogLogs.logger.warn(`Got illigal stage from BE: ${stage}, skipping`);
            return;
        }
        if (stage === this.currentStage) {
            return;
        }
        this.currentStage = stage;
        this.resetLastStageUpdateTime();
    }
}

const JobStatus = ({ sessionId }) => {
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('');
    const [sessionCrashed, setSessionCrashed] = useState(false);
    const isMobile = window.innerWidth <= 500;
    const statusTrackerRef = useRef(new JobStatusTracker());
    const toast = useRef(null);

    const closeErrorModal = () => {
        // TODO: Create a new session using common code with app.js
        setSessionCrashed(false);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
        const foundSessionId = Cookies.get('session_id');
        if (foundSessionId) {
            Cookies.remove('session_id');
        }
        window.location.reload();
    };

    useEffect(() => {
        const SESSION_STATUS_POLL_INTERVAL = 5 * 1000; // 5 sec
        setStatusMessage(
            <span>
                <span><span style={{ color: 'gold' }}>Initializing</span> ...</span>
            </span>
        );
        const interval = setInterval(() => {
            getSessionStatus(sessionId).then((stage) => {
                statusTrackerRef.current.setCurrentStage(stage);
                const status = statusTrackerRef.current.getCurrentStatus();
                setStatusMessage(status);
                // redirect to generated images page if status is completed
                if (stage === 'completed') {
                    navigate(`/generated-images?session_id=${sessionId}`);
                }
                else if (stage === 'crashed') {
                    clearInterval(interval);
                    setSessionCrashed(true);
                }
            });
        }, SESSION_STATUS_POLL_INTERVAL);
        return () => clearInterval(interval);
    }, [sessionId, navigate]);

    return (
        <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                <Loader type='line-scale' active={true} style={{ opacity: isMobile ? 0.7 : 0.5 }} />
                <div style={{ opacity: isMobile ? 0.9 : 0.8, margin: 0, fontWeight: 'bold' }}>
                    {statusMessage}
                </div>
            </div>
            <Modal
                isOpen={sessionCrashed}
                onRequestClose={closeErrorModal}
                contentLabel="Error Modal"
                appElement={document.getElementById('root')}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '80%' : '30%',
                        whiteSpace: 'wrap',
                        border: '1px solid #ccc',
                        background: '#101215',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '10px',
                        outline: 'none',
                        padding: '20px',
                        textAlign: 'center' // Center the text horizontally
                    }
                }}
            >
                <div>
                    <Toast ref={toast} className="center-toast" position={isMobile ? 'bottom-center' : 'top-right'}></Toast>
                    <h2>Error while creating images 😒</h2>
                    <p>We are very sorry! But there was an error while creating your images. Please try again, these errors often resolve on their own. If this continues please contact <a href="mailto:support@eikona.io">support@eikona.io</a>.</p>
                    <button
                        onClick={closeErrorModal}
                        style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            background: '#101215',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            color: '#fff',
                            fontSize: '16px',
                        }}
                    >
                        Close
                    </button>
                </div >
            </Modal>
        </div>
    )
}

export default JobStatus;