import Lottie from "lottie-react";
import animationData from "./animation.json";
import { useEffect, useState } from "react";

const UsageCreative = () => {
  const [playAnimation, setPlayAnimation] = useState(false);
  const isMobile = window.innerWidth <= 500;

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("usage-creative");
      if (element) {
        const rect = element.getBoundingClientRect();
        // the animation should play only if fully visible
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          if (!playAnimation) {
            setPlayAnimation(true);
          }
        } else {
          // the animation should stop only if fully hidden
          if (rect.top > window.innerHeight || rect.bottom < 0) {
            if (playAnimation) {
              setPlayAnimation(false);
            }
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [playAnimation]);

  return (
    <div id="usage-creative" style={{ width: '100%', height: isMobile ? '40vh' : '50vh' }}>
      {playAnimation && (
        <Lottie animationData={animationData} style={{ width: '100%', height: isMobile ? '40vh' : '50vh' }} />
      )}
    </div>
  );
};

export default UsageCreative;

