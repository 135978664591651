import React from 'react';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { useRef, useState, useEffect } from 'react';
import "./MongoCharts.css";

export const AtlasMongoChart = ({ chartId, width, height, session_id }) => {

    const sdk = new ChartsEmbedSDK({ baseUrl: 'https://charts.mongodb.com/charts-project-0-rjnbwzu' });
    const chartDiv = useRef(null);
    const [rendered, setRendered] = useState(false);
    const renderingSpec = {
        version: 1,
        options: {
            labelSize: 150,
        }
    }
    const [chart] = useState(sdk.createChart({
        chartId: chartId, width: width, height: height,
        background: 'transparent',
        theme: 'light',
        showAttribution: false,
        renderingSpec: renderingSpec,
        attribution: true,
        refreshInterval: 20,
        maxDataAge: 10
    }));
    useEffect(() => {
        chart.render(chartDiv.current).then(() => setRendered(true)).catch((err) => console.log('Error during Charts rendering.', err));
    }, [chart]);

    useEffect(() => {
        if (rendered) {
            chart.setFilter({"session_id": session_id}).catch(err => console.log("Error while filtering.", err));
        }
    }, [chart, rendered, session_id]);

    return <div className="chart" ref={chartDiv} style={{ height: '100%', width: '100%', backgroundColor: 'transparent' }} />;
};

export const SimilarityNearestScoresChart = ({ width, height, session_id }) => {
    const similarityScoresChartId = "663b3c67-221c-47f8-8271-7de805fd433b";
    return <AtlasMongoChart chartId={similarityScoresChartId} width={width} height={height} session_id={session_id} />
}

export const SimilarityAvgScoresChart = ({ width, height, session_id }) => {
    const similarityScoresChartId = "8693c10a-a563-433b-8164-fc40227452ad";
    return <AtlasMongoChart chartId={similarityScoresChartId} width={width} height={height} session_id={session_id} />
}

export const SimilarityMaxDistScoresChart = ({ width, height, session_id }) => {
    const similarityScoresChartId = "4c3c5b9e-882b-4ed4-883e-74e62844de40";
    return <AtlasMongoChart chartId={similarityScoresChartId} width={width} height={height} session_id={session_id} />
}

export const PickScoresChart = ({ width, height, session_id }) => {
    const pickScoresChartId = "6644b85e-c407-4cb8-8096-8e5040712d7c";
    return <AtlasMongoChart chartId={pickScoresChartId} width={width} height={height} session_id={session_id} />
}

export const AggregatedImageStarsChart = ({ width, height, session_id }) => {
    const aggregatedImageStarsChartId = "663b360a-6bc2-4f59-83b5-36e57d019222";
    return <AtlasMongoChart chartId={aggregatedImageStarsChartId} width={width} height={height} session_id={session_id} />
}

export const ImageStarsByEpochChart = ({ width, height, session_id }) => {
    const imageStarsByEpochChartId = "663ba790-8af3-4e55-8f37-a4367e79d9d5";
    return <AtlasMongoChart chartId={imageStarsByEpochChartId} width={width} height={height} session_id={session_id} />
}

export const ImageStarsByCFGChart = ({ width, height, session_id }) => {
    const imageStarsByCFGChartId = "6644cd6b-6d0f-4bdf-8e0f-37458a35ba5b";
    return <AtlasMongoChart chartId={imageStarsByCFGChartId} width={width} height={height} session_id={session_id} />
}
