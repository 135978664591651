import React from 'react';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GeneratedImages from './components/generated_images/GeneratedImages';
import HomePage from './components/homepage/HomePage';
import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';
import "./App.css"
import { v4 as uuidv4 } from 'uuid';
import { api_server_url } from './components/utils/constants';
import GeneratedImagesEmail from './components/generated_images/GeneratedImagesEmail';
import { datadogLogs } from '@datadog/browser-logs'
import { getSessionStatus } from './components/utils/jsUtils';
import { animateScroll } from 'react-scroll';
import AdminPage from './components/admin/AdminPage';
import PrivacyPolicy from './components/privacy_and_terms/PrivacyPolicy';
import TermsOfService from './components/privacy_and_terms/TermsOfService';
import Blog from './components/blog/Blog';
import ConsentPopup from './components/privacy_and_terms/ConsentPopup';
import EikonaDashboard from './components/admin/EikonaDashboard';

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [consent, setConsent] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [userUploadedImages, setUserUploadedImages] = useState(false);
  const [consentModalIsOpen, setConsentModalIsOpen] = useState(true);
  const [finishedTyping, setFinishedTyping] = useState(false);
  const foundSessionId = Cookies.get('session_id');

  // init mixpanel and datadog on app mount
  useEffect(() => {
    const mixpanelToken = process.env.REACT_APP_MIXPANEL_HUE_TOKEN;
    const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
    const datadogSite = process.env.REACT_APP_DATADOG_SITE;
    const appEnv = process.env.REACT_APP_ENVIRONMENT;
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
    mixpanel.opt_out_tracking()
    datadogLogs.init({
      clientToken: datadogClientToken,
      site: datadogSite,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'hue-frontend',
      forwardConsoleLogs: 'all',
      trackingConsent: 'not-granted',
      env: appEnv,
    })
    setIsInitialized(true);
  }, [setIsInitialized]);

  // session_id cookie
  useEffect(() => {
    if (foundSessionId) {
      setSessionId(foundSessionId);
      setConsent(true); // he already consent to cookies before, so we don't need to ask again
      getSessionStatus(foundSessionId).then((stage) => {
        if (stage && stage !== 'created' && stage !== 'completed') {
          setUserUploadedImages(true);
          animateScroll.scrollToBottom();
        }
      });
      datadogLogs.logger.info(`using session_id cookie`, { 'hue-session-id': foundSessionId })
    } else {
      if (!sessionId) {
        const newSessionId = uuidv4();
        datadogLogs.logger.info('New session started', { 'session-id': newSessionId });
        setSessionId(newSessionId);
        fetch(`${api_server_url}/create_new_session?session_id=${newSessionId}`, {
          method: 'POST',
          redirect: 'follow',
        })
          .then(response => response.json())
          .then(data => datadogLogs.logger.info('Created new session', { 'data': data, 'hue-session-id': newSessionId }))
          .catch((error) => {
            datadogLogs.logger.error('Error while creating a new session:', { 'error-message': error.message, 'hue-session-id': newSessionId });
          });
      }
      mixpanel.identify(sessionId)
    }
  }, [consent, sessionId, foundSessionId])

  useEffect(() => {
    if (consent) {
      mixpanel.opt_in_tracking();
      datadogLogs.setTrackingConsent('granted');
      datadogLogs.logger.info('Consent granted');
      if (!foundSessionId && sessionId !== '') {
        // set new cookie since the user consent to cookies
        Cookies.set('session_id', sessionId, { expires: 7 });
      }
    }
  }, [consent, sessionId, foundSessionId])

  return (
    <Router>
      <Routes>
        {isInitialized && <Route path="/" exact element={<HomePage sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} finishedTyping={finishedTyping} setFinishedTyping={setFinishedTyping} />} />}
        {isInitialized && <Route path="/generated-images" element={<GeneratedImages />} />}
        {isInitialized && <Route path="/generated-images-email" element={<GeneratedImagesEmail />} />}
        {isInitialized && <Route path="/admin" element={<AdminPage />} />}
        {isInitialized && <Route path="/privacy-policy" element={<PrivacyPolicy />} />}
        {isInitialized && <Route path="/terms-of-service" element={<TermsOfService />} />}
        {isInitialized && <Route path="/blog" element={<Blog />} />}
        {isInitialized && <Route path="/eikona-dashboard" element={<EikonaDashboard />} />}
        {/* Add more routes as needed */}
      </Routes>
      {finishedTyping && !consent &&
        <ConsentPopup sessionId={sessionId} setConsent={setConsent} modalIsOpen={consentModalIsOpen} setModalIsOpen={setConsentModalIsOpen} />}
    </Router>
  );
}

export default App;