import React from 'react';
import getResizedImageUrl  from '../../utils/getResizedImageUrl';
import useWindowSize from '../../utils/useWindowSize'

function BoxedImage({ vh, vw, imageId }) {
    const [windowWidth, windowHeight] = useWindowSize();
    const actualHeight = parseInt(windowHeight * (vh / 100));
    const actualWidth = parseInt(windowWidth * (vw / 100));

    const imageUrl = getResizedImageUrl(imageId, actualWidth, actualHeight);
    return (
        <div style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            borderRadius: '15px',
            height: `${vh}vh`,
            width: `${vw}vw`,
            transition: 'all 0.3s ease',
            ':hover': {
                transform: 'translateY(-10px)',
            },
        }} />
    );
}

export default BoxedImage;


