import React, { useState } from "react";
import { Rating } from "primereact/rating";
import { setImageRating } from "../utils/jsUtils"

const ImageRating = ({ imageId, initialRating }) => {
    const [rating, setRating] = useState(initialRating);

    const handleRatingChange = (value) => {
        setRating(value);
        setImageRating(imageId, value);
    }

    return (
        <div className="card flex justify-content-center">
            <Rating value={rating} onChange={(e) => handleRatingChange(e.value)} cancel={false} />
        </div>
    );
}

export default ImageRating;