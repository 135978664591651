import "./Intro.css"
import ImagePalette from "./ImagePallete";
import TextSection from "./TextSection";
import TryIt from "./tryit/TryIt";
import UsageCreative from "./UsageCreative/UsageCreative";

function Intro({ backgroundImage, sessionId, userUploadedImages, setUserUploadedImages }) {
    const isMobile = window.innerWidth <= 500;
    const firstBoxImages = [
        [
            'nir1.jpeg',
            'nir2.jpeg',
            'nir3.jpeg'
        ],
        [
            'maya1.jpeg',
            'maya4.jpeg',
            'maya2.jpeg'
        ],
        [
            'nurit10.jpeg',
            'nurit1.jpeg',
            'nurit11.jpeg'
        ],
    ];
    const secondBoxImages = [
        [
        'omer8.jpeg',
        'omer2.jpeg',
        'omer5.jpeg'
        ],
        [
        'yuval13.jpeg',
        'yuval6.jpeg',
        'yuval3.jpeg'
        ],
        [
            "faith4.png",
            "faith8.png",
            "faith1.png",
        ]
    ];
    const bigBoxMiddleSectionVh = isMobile ? 50 : 60;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', background: `url(${backgroundImage})`, alignItems: 'center' }}>
            <div style={{ width: '100%', height: '40vh' }}>
            </div>
            <div className="intro-header" style={{ width: '100%', height: isMobile ? '15vh' : '20vh' }}>
                <h1>Introducing selfyx.</h1>
            </div>
            <UsageCreative />
            <div className="intro-animation" style={{ width: '95vw', height: '15vh' }}>
                <h1 style={{fontSize: 'calc(15px + 2vmin)'}}>
                    Upload your images from social media, and our AI will generate <br/>
                    <span> stunning new images of you! </span>
                </h1>
            </div> 
            <div style={{ width: '100%', height: isMobile ? '30vh' : '40vh' }}>
            </div>
            <TextSection header={
                "Your greatest photos **ever**"
            } text={
                "Let AI magic bring out the absolute best of you"
            } />
            <ImagePalette vw={90} vh={bigBoxMiddleSectionVh} imageGroups={firstBoxImages} alternate={isMobile} />
            <div style={{ width: '100%', height: '25vh' }}>
            </div>
            <TextSection header={
                "Model for top photographers"
            } text={
                "What would your professional shoot look like?"
            } />
            <ImagePalette vw={90} vh={bigBoxMiddleSectionVh} imageGroups={secondBoxImages} alternate={isMobile} />
            <div style={{ width: '100%', height: '25vh' }}>
            </div>
            <TryIt sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} />
        </div>
    )
}

export default Intro;