import React, { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import isDuplicate from './image_compare/imageCompare';
import useServerConfig from '../../../../utils/useServerConfig';
import "./flags.css"
import Loader from 'react-loaders';
import mixpanel from 'mixpanel-browser';
import { datadogLogs } from '@datadog/browser-logs'
import { api_server_url } from "../../../../utils/constants";
import { uploadToCloudinary } from "../../../../utils/jsUtils";
import "./GallaryUploadWidget.css";


const fileValidState = 'valid';
const fileInvalidState = 'invalid';

const GallaryUploadWidget = ({ sessionId, setUserUploadedImages, closeModal }) => {
    const toast = useRef(null);
    const [validImages, setValidImages] = useState(0);
    const fileUploadRef = useRef(null);
    const serverConfig = useServerConfig();
    const nofRequiredImages = serverConfig?.upload_widget?.nof_required_images;
    const nofMaxImages = serverConfig?.upload_widget?.nof_max_images;
    const [filesState, setFilesState] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [validImagePaths, setValidImagePaths] = useState([]);
    const [completedUploads, setCompletedUploads] = useState([]);
    const isMobile = window.innerWidth <= 500;

    // zero valid images on mount
    useEffect(() => {
        setValidImages(0);
        setValidImagePaths([]);
        setFilesState({});
        setCompletedUploads([]);
    }, []);

    const onTemplateSelect = async (e) => {
        let _validImages = validImages;
        let files = e.files;
        let _filesState = filesState;
        let _validImagePaths = validImagePaths;

        for (let key of Object.keys(files)) {
            if (_filesState.hasOwnProperty(files[key].name)) {
                // file was already compared
                continue;
            }
            let filePath = URL.createObjectURL(files[key]);
            let isDuplicateResult = await isDuplicate(filePath, _validImagePaths, serverConfig?.upload_widget?.ssim_threshold, serverConfig?.upload_widget?.ssim_max_image_size);
            if (!isDuplicateResult) {
                _filesState[files[key].name] = fileValidState;
                _validImages += 1;
                _validImagePaths = [..._validImagePaths, filePath];
            } else {
                _filesState[files[key].name] = fileInvalidState;
            }
        }

        setFilesState(_filesState);
        setValidImages(_validImages);
        setValidImagePaths(_validImagePaths);
        mixpanel.track("Selected images", {
            "Session ID": sessionId,
            "Number of Images": files.length,
            "Number of valid Images": _validImages
        });
    };

    const onUploadFinished = async () => {
        datadogLogs.logger.info('All the uploads are Done!', { 'hue-session-id': sessionId, 'completedUploads': completedUploads });
        mixpanel.track("Manually Upload Images", {
            "Session ID": sessionId,
            "Upload IDs": completedUploads,
            "Number of Images": completedUploads.length
        });
        // Meta Pixel track custom event
        window.fbq('track', 'Subscribe', {});
        fetch(`${api_server_url}/preprocess_train_and_generate?session_id=${sessionId}`, {
            method: 'POST',
            redirect: 'follow',
        })
            .then(response => response.json())
            .then(data => datadogLogs.logger.info('Finished preprocessing, training and generation', { 'data': data, 'hue-session-id': sessionId }))
            .catch((error) => {
                datadogLogs.logger.error('Error while preprocessing, training and generation:', { 'error-message': error.message, 'hue-session-id': sessionId });
            });
        closeModal();
        setUserUploadedImages(true);
    };

    const uploadHandler = async (e) => {
        setInProgress(true);
        const filesToUpload = e.files.slice(0, nofMaxImages);
        Promise.all(filesToUpload.map(async (file) => {
            const fileState = filesState[file.name];
            if (fileState === fileInvalidState) {
                return Promise.resolve();
            }

            return uploadToCloudinary(file, sessionId)
                .then(data => {
                    setCompletedUploads([...completedUploads, data['public_id']]);
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: 'Upload Failed', detail: 'Error uploading file' });
                });
        })).then(() => {
            onUploadFinished();
        });
    };

    const onImageRemove = (file, callback) => {
        let _fileState = filesState;
        if (_fileState[file.name] === fileValidState) {
            // remove valid image from all states
            let _validImages = validImages;
            if (_validImages > 0) {
                _validImages -= 1;
            }
            setValidImages(_validImages);
            setValidImagePaths(validImagePaths.filter(path => path !== file.objectURL));
            _fileState[file.name] = null;
            setFilesState(_fileState);
        }
        callback();
    };

    const onImageClear = () => {
        setValidImages(0);
    };

    const header = (options) => {
        const { className, chooseButton, uploadButton } = options;
        const value = validImages * 100 / nofRequiredImages;
        const isUploadDisabled = validImages < nofRequiredImages;

        return (
            <div className={className} style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    {chooseButton}
                    {React.cloneElement(uploadButton, { disabled: isUploadDisabled })}
                </div>
                <div className="flex align-items-center gap-3 ml-auto">
                    <span> {validImages} / {nofRequiredImages} Valid Images</span>
                </div>
                <div>
                    <ProgressBar value={value} showValue={false} style={{ width: isMobile ? '8rem' : '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        const fileState = filesState[file.name];
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center justify-content-between" style={{ width: '100%' }}>
                    <div style={{ width: isMobile ? '38%' : '20%', opacity: fileState === fileInvalidState ? 0.4 : 1 }}>
                        <img alt={file.name} role="presentation" src={file.objectURL} style={{ width: '100%' }} />
                    </div>
                    {!isMobile &&
                        <div style={{ width: '34%', opacity: fileState === fileInvalidState ? 0.6 : 1 }}>
                            <span className="flex flex-column text-center" style={{ paddingLeft: '10px' }}>
                                {file.name}
                            </span>
                        </div>
                    }
                    <div style={{ width: isMobile ? '40%' : '34%', opacity: fileState === fileInvalidState ? 0.6 : 1 }}>
                        {fileState === fileInvalidState && <span className="pi pi-exclamation-triangle"> {!isMobile ? 'Duplicated / ' : 'Too '} Similar </span>}
                    </div>
                    <div style={{ width: isMobile ? '18%' : '12%' }}>
                        {!inProgress && <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onImageRemove(file, props.onRemove)} />}
                        {inProgress && fileState === fileValidState && <Loader type='line-scale-pulse-out-rapid' active={true} style={{ opacity: 0.6 }} />}
                    </div>
                </div>
            </div>
        );
    };

    const empty = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)', textAlign: 'center' }} className="my-5">
                    Please upload at least {nofRequiredImages} high-quality photos of yourself. <br />
                    Photos with friends are fine too!<br /><br />
                    By uploading photos, I consent to the <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>, and to the processing of my personal data in accordance with the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </span>
            </div>
        );
    };

    const chooseOptions = {
        icon: 'pi pi-fw pi-images',
        iconOnly: true,
        className: `custom-choose-btn p-button-rounded p-button-outlined ${validImages === 0 ? 'glow-animation' : ''}`,
    };
    const uploadOptions = {
        icon: 'pi pi-fw pi-cloud-upload',
        iconOnly: true,
        className:
            `custom-upload-btn p-button-success p-button-rounded p-button-outlined ${validImages >= nofRequiredImages ? 'glow-animation' : ''}`
    };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    const showChooseMessage = () => {
        toast.current.show({
            severity: 'info',
            summary: 'Choose your images',
            detail: 'Press the glowing button to choose your images',
            life: 3000,
        });
    };

    const showUploadMessage = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Upload your images',
            detail: 'Press the glowing button to upload your images',
            life: 3000,
        });
    };

    useEffect(() => {
        if (validImages === 0) {
            showChooseMessage();
        }
    }, [validImages]);

    useEffect(() => {
        if (validImages >= nofRequiredImages) {
            showUploadMessage();
        }
    }, [validImages, nofRequiredImages]);

    return (
        <div>
            <Toast ref={toast} className="center-toast" position={isMobile ? 'bottom-center' : 'top-right'}></Toast>

            {!isMobile && <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />}
            {!isMobile && <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />}
            {!isMobile && <Tooltip target=".custom-cancel-btn" content="Clear All" position="bottom" />}

            <FileUpload ref={fileUploadRef} name="demo[]" customUpload={true} multiple accept="image/*" maxFileSize={20000000}
                onSelect={onTemplateSelect} onError={onImageClear} onClear={onImageClear}
                headerTemplate={header} itemTemplate={itemTemplate} emptyTemplate={empty} uploadHandler={uploadHandler}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
        </div >
    )
}

export default GallaryUploadWidget;
