const isMobile = window.innerWidth <= 500;
const isLargeScreen = window.innerWidth > 1440;
const isVeryLargeScreen = window.innerWidth > 2000;
export const api_server_url = process.env.REACT_APP_TRAINING_API_SERVER_URL;

export var glowingFontSize = '';
export var glowingFontSizeSmall = '';

if (isMobile) {
    glowingFontSize = '35px';
    glowingFontSizeSmall = '21px';
} else {
    if (isVeryLargeScreen) {
        glowingFontSize = '80px';
        glowingFontSizeSmall = '25px';
    }
    else {
        if (isLargeScreen) {
            glowingFontSize = '60px'
            glowingFontSizeSmall = '25px'
        }
        else {
            glowingFontSize = '50px'
            glowingFontSizeSmall = '25px'
        }
    }
}