import React from 'react';
import './PolicyAndTerms.css';


const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div class="MarkdownStyles_markdown-legal__4sC8d undefined">
                <h1 class="text-center pb-8">Privacy Policy</h1>
                <p>Effective Date: <strong>1/07/2024</strong></p>
                <p>Eikona Technologies Inc. ("<strong>Eikona</strong>", "<strong>we</strong>", "<strong>us</strong>") is committed to protecting your privacy. This Privacy Policy ("Policy") outlines our practices regarding the collection, use, and disclosure of personal information ("Personal Data") obtained through www.selfyx.com ("Website") and our associated services ("Services"). This Policy also details your rights concerning your Personal Data and how you can contact us with any inquiries or concerns.</p>
                <h3>1. Collection of Personal Data</h3>
                <p>"Personal Data" refers to any data that identifies or can be used to identify a person. We collect this data in the following ways:</p>
                <ul>
                    <li><strong>Contact details</strong>: We collect data through the registration process and when you set up your profile or contact us by mail, such as your name, company details, email, and address.</li>
                    <li><strong>Photos of you</strong>: We require photos of you to create an accuracte AI model. To use selfyx.com, you need to provide us with at least 15 photos of yourself, either directly uploaded from your device, or by connecting a 3rd party social app.</li>
                    <li><strong>Usage Data</strong>: Automatically collected data includes your interactions with our Services, IP address, device information, and activity on our Website.</li>
                    <li><strong>Data from Third Parties</strong>: We receive data from social media platforms and other external sources that you connect with our Services.</li>
                </ul>
                <h3>2. Use of Personal Data</h3>
                <p>We use your photos to model your appearance using today's cutting edge AI tools.<br></br>We use your Personal Data to provide and improve our Services, personalize your experience, conduct business operations such as customer service, marketing and analytics, and comply with legal obligations.<br></br>We may process your Personal Data to create anonymized or aggregated data that does not include identifiable information, which we can utilize for any legal purposes.</p>
                <h3>3. Disclosure of Personal Data</h3>
                <p>We may share your data with our service providers such as cloud hosting platforms, email automation and error logging services.<br></br>We may disclouse ypur Personal Data if required so by law, as a result of a court order or other lawful govermental order.<br></br>In case of a merger, sale or other transfer of company assets, your Personal Data may be transfered to the new lawful body. In that case, the usage of Personal Data will be limited to the provisions described in this policy.</p>
                <h3>4. Data Retention</h3>
                <p>Your contact information is securly stored in our systems using state of the art encryption and web security measures.<br></br>Aggregate photo data may also be kept for analytics purposes and improvment of services. In this case, it will be kept anonymized and encrypted for a period of no more than 60 days.</p>
                <h3>5. Your Rights and Choices</h3>
                <p>You have the right to update or delete your personal information at any time. You can also request that your information will not be used for analytics, and not be deleted. Email us to <a href="mailto:support@eikona.io">support@eikona.io</a> with any request, we are commited to your privacy and rights.</p>
                <h3>6. Third-Party Integrations</h3>
                <p>Our Services may include integrations and links to other sites and services, such as Openai, which are not governed by this Policy. We advise you to review the privacy policies of these third-party sites. Any third party that you choose to connect to the app will subject you to the privacy policy of that third party, in addition to this current policy.</p>
                <h3>7. Security Measures</h3>
                <p>We implement cutting edge security measures to protect your Personal Data. We abite to the highest standards in web application architecture and regulations, and spare no expenses in terms of Personal Data security. However, no digital system is infallible. Our representatives are always available in the support mail, for any concern or request.</p>
                <h3>8. Protection of Minors</h3>
                <p>We do not knowingly collect Personal Data from children under 18. If it comes to our attention that a child under 18 has provided us with Personal Data, we will take steps to delete such information.</p>
                <h3>9. International Data Transfers</h3>
                <p>Our services are hosted in Europe and operated from Israel. If you use our service from regions outside Europe, note that your Personal Data will transfered, processed and stored in servers located in Europe, and under Israeli law and regulations.</p>
                <h3>10. Updates to this Policy</h3>
                <p>We may update this Policy periodically. The date of the most recent revisions will appear on this page.</p>
                <h3>11. Contact Us</h3>
                <p>If you have any questions about this Policy or our treatment of your Personal Data, please contact us at <a href="mailto:support@eikona.io">support@eikona.io</a> or by mail at Eikona Technologies Inc., Balfour street 51, Tel-Aviv, Israel, PO 6521221.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;