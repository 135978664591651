import { useState, useEffect } from "react";
import TextCreative from "./TextCreative.js";
import useWindowSize from "../../utils/useWindowSize";
import getResizedImageURl from "../../utils/getResizedImageUrl";
import GlowingButton from "../../buttons/GlowingButton.js";

function ChangingBackground({ finishedTyping, setFinishedTyping }) {
    const [screenWidth, screenHeight] = useWindowSize();
    const [bgIndex, setBgIndex] = useState(-1);
    const [pageLoaded, setPageLoaded] = useState(false);
    // choose images aspect ratio
    let wideImages = screenHeight < screenWidth;
    var bgImages = wideImages ? [
        `v1701513866/image2_e30dpv.jpg`,
        `v1701517091/image4_jpctfb.jpg`,
        `v1701513989/wide_bg2_vnw257.jpg`,
        `v1701514747/wide_bg1_wkmjkv.jpg`
    ] :
        [
            `v1701513866/tall_bg1_vlrcwj.jpg`,
            `v1701517091/tall_bg5_u0tqpx.jpg`,
            `v1701513989/tall_bg2_h7abqa.jpg`,
            `v1701514747/tall_bg3_zwau1v.jpg`
        ];

    bgImages = bgImages.map(image => getResizedImageURl(image, screenWidth, screenHeight + 80));

    // preload images
    useEffect(() => {
        // preload images during typing text phase
        bgImages.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    })

    window.incrementBgIndex = () => {
        // used to control the background change from another page
        setBgIndex((prev) => (prev + 1) % bgImages.length);
    };
    // background change effect
    useEffect(() => {

        let timeoutDuration = bgIndex === -1 ? 3000 : 1300;
        const timeout3 = setTimeout(() => {
            if (window.disableBackgroundChange === true && bgIndex !== -1) {
                // used to control the background change from another page
                return;
            }
            setBgIndex((prev) => (prev + 1) % bgImages.length);
        }, timeoutDuration);

        return () => clearTimeout(timeout3);
    }, [bgIndex, bgImages.length]);

    useEffect(() => {
        setPageLoaded(true);
    }, []);

    return (
        <div className="App-header" style={{

            backgroundImage: `url(${bgImages[bgIndex]})`,
            backgroundSize: 'cover',
            width: '100%',
            height: `${screenHeight + 80}px`,
            position: 'relative'  // Ensure the container can use absolute positioning for its children
        }}>
            {pageLoaded && <TextCreative finishedTyping={finishedTyping} setFinishedTyping={setFinishedTyping} />}

            <div style={{ position: 'absolute', bottom: '15vh', width: '100%' }}>
                <div>
                    <img src="./camera.png" alt="logo" style={{ width: wideImages ? '90px' : '70px', height: 'auto' }} />
                </div>
                <div style={{ height: '5vh' }}></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <GlowingButton text="Try it FREE &#x2192;" onClick={() => window.uploadModalOpen()}></GlowingButton>
                </div>
            </div>
        </div>
    );
}

export default ChangingBackground;