import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PrimeReactProvider } from 'primereact/api';

const google_oauth_client_id = process.env.REACT_APP_GOOGLE_OAUTO_CLIENT_ID;

function loadFacebookSDK() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: '399826349054691', // Replace with your Facebook app ID
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v14.0' // Use the latest version
    });
  };

  // Load the SDK asynchronously
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}

// Call the function to load the SDK
loadFacebookSDK();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={google_oauth_client_id}>
    <PrimeReactProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PrimeReactProvider>
  </GoogleOAuthProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
