import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Navigation, Keyboard } from 'swiper/modules';
import ImageRating from '../image_rating/ImageRating';
import { useState, useRef } from 'react';

const UserPhotos = ({ imageUrls, imageIds, imageRating: imageRatings }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 30; // Adjust this value as needed
    const totalPages = Math.ceil(imageUrls.length / imagesPerPage);
    const swiperRef = useRef(null);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            if (swiperRef.current) {
                if (currentPage < newPage) {
                    swiperRef.current.swiper.slideTo(0);
                } else {
                    swiperRef.current.swiper.slideTo(imagesPerPage - 1);
                }
            }
            setCurrentPage(newPage);
        }
    };

    const paginatedImageUrls = imageUrls.slice((currentPage - 1) * imagesPerPage, currentPage * imagesPerPage);
    const paginatedImageIds = imageIds ? imageIds.slice((currentPage - 1) * imagesPerPage, currentPage * imagesPerPage) : [];

    return (
        <div>
            <Swiper
                ref={swiperRef}
                modules={[EffectCards, Autoplay, Navigation, Keyboard]}
                effect="cards"
                style={{ width: '55vh', height: '55vh' }}
                navigation={{ enabled: true }}
                keyboard={{ enabled: true }}
                lazyPreloadPrevNext={5}
            >
                {paginatedImageUrls && paginatedImageUrls.map((imageUrl, index) => (
                    <SwiperSlide key={index}>
                        <div style={{ position: 'relative', alignItems: 'center', fontWeight: 'bold', fontSize: '2.5vh' }}>
                            <img src={imageUrl} alt={`Processed ${index}`} loading='lazy' />
                            <div style={{ position: 'absolute', bottom: '10vh', width: '100%', textAlign: 'center', color: 'white' }}>
                                {`Image ${index + 1 + (currentPage - 1) * imagesPerPage}`}
                                {imageIds && Object.keys(imageRatings).length > 0 && imageIds[index] && <div>
                                    <div style={{ height: '3vh' }}></div>
                                    <div>
                                        <ImageRating 
                                            key={`${paginatedImageIds[index]}-${currentPage}`} // Add key prop
                                            imageId={paginatedImageIds[index]} 
                                            initialRating={imageRatings[paginatedImageIds[index]]} 
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '30px', paddingTop: '10px' }}>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}
                    style={{
                        margin: '0 5px', padding: '10px 10px', borderRadius: '5px', backgroundColor: 'transparent', color: 'white',
                        border: '1px solid white', cursor: 'pointer', width: '80px', fontWeight: 'bold'
                    }}>
                    Previous
                </button>
                <span style={{ margin: '0 10px', color: 'white' }}>
                    {`Viewing ${((currentPage - 1) * imagesPerPage) + 1}-${Math.min(currentPage * imagesPerPage, imageUrls.length)} of ${imageUrls.length}`}
                </span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * imagesPerPage >= imageUrls.length}
                    style={{
                        margin: '0 5px', padding: '10px 20px', borderRadius: '5px', backgroundColor: 'transparent', color: 'white',
                        border: '1px solid white', cursor: 'pointer', width: '80px', fontWeight: 'bold'
                    }}>
                    Next
                </button>
            </div>
        </div>
    )
}

export default UserPhotos;
