import React from 'react';

const EikonaDashboard = () => {
    return (
        <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
            <div style={{ 
                width: '100%', 
                height: '80px', 
                backgroundColor: '#eeefe9', 
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 2
            }}>
                <img
                    src="/eikona.png"
                    alt="Eikona Logo"
                    style={{
                        position: 'absolute',
                        top: '3px',
                        right: '10px',
                        width: '80px',
                        height: 'auto',
                        zIndex: 3
                    }}
                />
            </div>
            <div style={{ height: 'calc(200vh - 80px)', overflow: 'auto', backgroundColor: '#eeefe9'}}>
                <iframe
                    title="Eikona Dashboard 1"
                    width="100%"
                    height="50%"
                    frameBorder="0"
                    allowFullScreen
                    src="https://us.posthog.com/embedded/3-71mc46M4SciOpy60XeIF5c6kNCxw"
                    style={{ border: 'none' }}
                ></iframe>
                    <iframe
                        title="Eikona Dashboard 2"
                    width="50%"
                    height="50%"
                    frameBorder="0"
                    allowFullScreen
                    src="https://us.posthog.com/embedded/ShyvHRbg1ZSzn_UY8e-M2gKLdv_D3Q"
                        style={{ border: 'none' }}
                    ></iframe>
            </div>
        </div>
    )
}

export default EikonaDashboard;