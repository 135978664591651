import "./Intro.css"

function TextSection({header, text}) {
    const formattedHeader = header.split('**');
    return (
        <div className="intro-explainer-bold" style={{ width: '90%' }}>
            <div style={{ width: '100%', textAlign: 'left' }}>
                <h1 style={{ textAlign: 'left' }}>
                    {formattedHeader.map((word, index) => 
                        index % 2 === 0 ? word : <span className="intro-explainer-bold">{word}</span>
                    )}
                </h1>
                <h2>{text}</h2>
            </div>
        </div>
    )
}
export default TextSection;