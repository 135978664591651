import React, { useState } from 'react';
import Modal from 'react-modal';
import GlowingButton from '../../../../../buttons/GlowingButton.js'
import { api_server_url } from '../../../../../utils/constants.js';
import { validateEmail } from '../../../../../utils/jsUtils.js';
import { datadogLogs } from '@datadog/browser-logs'
import mixpanel from 'mixpanel-browser';


const EmailModal = ({ isOpen, onRequestClose, sessionId }) => {
    const [email, setEmail] = useState('');
    const [isInvalidValidEmail, setInvalidValidEmail] = useState(false);
    const isMobile = window.innerWidth <= 500;

    const openInvalidEmailModal = () => {
        setInvalidValidEmail(true);
    }

    const closeInvalidEmailModal = () => {
        setInvalidValidEmail(false);
    }

    const handleSubmit = (event) => {
        if (!validateEmail(email)) {
            openInvalidEmailModal();
            return
        }
        event.preventDefault();
        mixpanel.track("User left email for notification", {
            "Session ID": sessionId,
            "Email": email
        });
        datadogLogs.logger.info('User left email for notification', {
            "hue-session-id": sessionId,
            "email": email
        });
        fetch(`${api_server_url}/handle_login?user_id=${email}&identifier_type=email&session_id=${sessionId}`, {
            method: 'POST',
            redirect: 'follow',
        }).then(response => response.json()).catch(error => {
            datadogLogs.logger.error('Error on email login:', {
                "hue-session-id": sessionId,
                "email": email,
                "error": error
            });
        });
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Email Form"
            appElement={document.getElementById('root')}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobile ? '80vw' : '30vw',
                    border: '1px solid #ccc',
                    background: '#101215',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '10px',
                    outline: 'none',
                    padding: '20px'
                }
            }}
        >
            <h2>Want to be notified?</h2>
            <span>The process usually takes about 20 minutes. </span><br /><br />
            <span>Leave an email and we'll let you know once your shots are ready.</span><br /><br />
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="amazing@person.com"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        style={{ width: '100%', padding: '8px', margin: '10px 0', fontSize: '1em' }} // Increased font size
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <GlowingButton text="Submit" onClick={handleSubmit}></GlowingButton>
                </div>
            </form>
            <Modal
                isOpen={isInvalidValidEmail}
                onRequestClose={closeInvalidEmailModal}
                contentLabel="Invalid Email Modal"
                appElement={document.getElementById('root')}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '80%' : '30%',
                        whiteSpace: 'wrap',
                        border: '1px solid #ccc',
                        background: '#101215',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '10px',
                        outline: 'none',
                        padding: '20px',
                        textAlign: 'center' // Center the text horizontally
                    }
                }}    
            >
            <div>
                <h2>Invalid email entered</h2>
                    <p>Please enter a valid email address, so we can send you the photos once their ready 🪄👉🏻⏱️👉🏻✉️.</p>
                <button 
                onClick={closeInvalidEmailModal} 
                style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    background: '#101215',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: '#fff',
                    fontSize: '16px',
                }}
            >
                Close
            </button>
            </div >                
            </Modal>    
        </Modal>
    );
};

export default EmailModal;
