import React, { useEffect, useState } from 'react';
import { api_server_url } from '../utils/constants';


const ActiveSessionsChart = ({ googleAuthToken }) => {
    const [activeSessions, setActiveSessions] = useState([]);

    useEffect(() => {
        const fetchActiveSessions = async () => {
            try {
                const response = await fetch(`${api_server_url}/admin/get_active_sessions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${googleAuthToken}`
                    },
                });
                const data = await response.json();
                setActiveSessions(data);
            } catch (error) {
                console.error('Error fetching active sessions:', error);
            }
        };

        fetchActiveSessions();
        const interval = setInterval(fetchActiveSessions, 15000);

        return () => clearInterval(interval);
    }, [googleAuthToken]);

    const handleRetry = async (sessionId) => {
        try {
            const response = await fetch(`${api_server_url}/preprocess_train_and_generate?session_id=${sessionId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${googleAuthToken}`
                },
            });
            const data = await response.json();
            console.log('Retry response:', data);
        } catch (error) {
            console.error('Error retrying session:', error);
        }
    };

    return (
        <div>
            <div>
                <h3>Active Sessions Details</h3>
                <table>
                    <thead>
                        <tr>
                            <th style={{ paddingRight: '20px' }}>Session ID</th>
                            <th style={{ paddingRight: '20px' }}>Status</th>
                            <th style={{ paddingRight: '20px' }}>Created At</th>
                            <th style={{ paddingRight: '20px' }}>Last Used</th>
                            <th style={{ paddingRight: '20px' }}>Retry</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeSessions.map((session, index) => (
                            <tr key={index}>
                                <td style={{ paddingRight: '20px' }}>{session[0]}</td>
                                <td style={{ paddingRight: '20px' }}>{session[1]}</td>
                                <td style={{ paddingRight: '20px' }}>{session[2]}</td>
                                <td style={{ paddingRight: '20px' }}>{session[3]}</td>
                                <td style={{ paddingRight: '20px' }}>
                                    <button
                                        onClick={(e) => {
                                            handleRetry(session[0]);
                                            e.target.style.backgroundColor = 'green';
                                            e.target.style.border = 'none';
                                            e.target.style.color = 'white';
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            borderRadius: '5px',
                                            backgroundColor: '#3a393b',
                                            border: 'none',
                                        }}
                                    >
                                        Retry
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActiveSessionsChart;
