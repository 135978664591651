import { useState, useEffect } from 'react';
import yaml from 'js-yaml';
import { api_server_url } from './constants'

const useServerConfig = () => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    fetch(`${api_server_url}/get_server_config`)
      .then((response) => response.text())
      .then((yamlText) => {
        const configJson = yaml.load(yamlText);
        setConfig(configJson);
      })
      .catch((error) => console.error('Failed to load server config:', error));
  }, []);

  return config;
};

export default useServerConfig;
