import { useState, useRef, useEffect } from 'react';
import UploadButton from './UploadButton';
import JobStatus from './JobStatus';
import EmailModal from "./email_modal/EmailModal";
import { animateScroll } from 'react-scroll';
import { getUserMailBySessionId } from '../../../../utils/jsUtils';
import mixpanel from 'mixpanel-browser';

function UploadSection({ vh, vw, sessionId, userUploadedImages, setUserUploadedImages }) {
    const videoUrl = 'https://res.cloudinary.com/dxgcobmaz/video/upload/f_auto/v1713282349/shutterstock_1057901770_evvce9.mp4';
    const videoUrlMobile = 'https://res.cloudinary.com/dxgcobmaz/video/upload/f_auto/v1713299165/shutterstock_1057901770-2_lbybyr.mov';
    const videoRef = useRef(null);
    const isMobile = window.innerWidth <= 500;
    const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
    const [uploadModalsClosed, setUploadModalsClosed] = useState(true);
    const [videoPlaying, setVideoPlaying] = useState(false);

    const closeEmailModal = () => {
        setEmailModalIsOpen(false);
        setVideoPlaying(true);
        animateScroll.scrollToBottom();
        mixpanel.track('Email modal closed', {
            'Session ID': sessionId
        });
    };

    useEffect(() => {
        getUserMailBySessionId(sessionId).then((user_email) => {
            if (userUploadedImages && uploadModalsClosed && user_email == null) {
                setEmailModalIsOpen(true);
            }
        });
    // We don't want to run this effect when the sessionId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userUploadedImages, uploadModalsClosed])

    useEffect(() => {
        if (videoRef.current) {
            if (videoPlaying) {
                videoRef.current.play().catch(error => console.error("Video play failed", error));
            }
            else {
                videoRef.current.currentTime = 2.68;
                videoRef.current.pause();
            }
        }
    }, [videoPlaying]);

    return (
        <div style={{ position: 'relative' }}>
            <video
                ref={videoRef}
                style={{
                    borderRadius: '10vh',
                    objectFit: 'cover',
                    height: `${vh}vh`,
                    width: `${vw}vw`,
                    transition: 'all 0.3s ease',
                    opacity: 0.85,
                    boxShadow: '0 0 20px rgba(255, 255, 255, 0.5)',
                }}
                loop
                autoPlay
                muted
                playsInline
                preload='auto'
                id='video'
                type='video/mp4'
                onLoadStart={() => {
                    videoRef.current.pause()
                }}
            >
                <source src={isMobile ? videoUrlMobile : videoUrl} type='video/mp4' />
            </video>
            <div style={{
                position: 'absolute',
                top: isMobile ? '80%' : '50%',
                left: isMobile ? '50%' : '30%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
            }}>
                {/* either whow the upload button  */}
                {!userUploadedImages && <UploadButton sessionId={sessionId} setUserUploadedImages={setUserUploadedImages} setUploadModalsClosed={setUploadModalsClosed} />}
                {/* or the job status  */}
                {userUploadedImages && <JobStatus sessionId={sessionId} />}
                <EmailModal isOpen={emailModalIsOpen} onRequestClose={closeEmailModal} sessionId={sessionId} />
            </div>
        </div>
    );
}

export default UploadSection;


