import { useState, useEffect } from "react";
import Modal from 'react-modal';
import GallaryUploadWidget from "./GallaryUploadWidget";
import GooglePhotosLoginAndFetch from './third_party_upload/GooglePhotos';
import FacebookPhotosLoginAndFetch from './third_party_upload/Facebook';
import mixpanel from "mixpanel-browser";
import MediaSourceModal from "./media_source_modal/MediaSourceModal";
import ChooseSelfieModal from "./choose_selfie_modal/ChooseSelfieModal";
import { uploadImagesEndpoint } from "../../../../utils/jsUtils";
import CheckNofImagesModal from "./check_nof_images_modal/CheckNofImagesModal";

const UploadButton = ({ sessionId, setUserUploadedImages, setUploadModalsClosed }) => {
    const [gallaryUploadModalIsOpen, setGallaryUploadModalIsOpen] = useState(false);
    const [googleModalIsOpen, setGoogleModalIsOpen] = useState(false);
    const [facebookModalIsOpen, setFacebookModalIsOpen] = useState(false);
    const [mediaSourceModalIsOpen, setMediaSourceModalIsOpen] = useState(false);
    const [chooseSelfieModalIsOpen, setChooseSelfieModalIsOpen] = useState(false);
    const [checkNofImagesModalIsOpen, setCheckNofImagesModalIsOpen] = useState(false);
    const [userUploadedSelfie, setUserUploadedSelfie] = useState('');
    const [userConnectedGooglePhotos, setUserConnectedGooglePhotos] = useState(false);
    const [userConnectedFacebook, setUserConnectedFacebook] = useState(false);
    const [imageUrlsToUpload, setImageUrlsToUpload] = useState([]);
    const [enoughImagesFound, setEnoughImagesFound] = useState(null); // will be set by thrid party modals upon check of number of user images
    const isMobile = window.innerWidth <= 500;


    const openGallaryUploadWidget = () => {
        setGallaryUploadModalIsOpen(true);
        setUploadModalsClosed(false);
        window.disableBackgroundChange = true;
        mixpanel.track("Opened upload widget", {
            "Session ID": sessionId,
        });
    };

    const openGoogleModal = () => {
        setGoogleModalIsOpen(true);
        setUploadModalsClosed(false);
        window.disableBackgroundChange = true;
        mixpanel.track("Opened Google authentication widget", {
            "Session ID": sessionId,
        });
    };

    const openFacebookModal = () => {
        setFacebookModalIsOpen(true);
        setUploadModalsClosed(false);
        window.disableBackgroundChange = true;
        mixpanel.track("Opened Facebook authentication widget", {
            "Session ID": sessionId,
        });
    };

    const openMediaSourceModal = () => {
        setMediaSourceModalIsOpen(true);
        window.disableBackgroundChange = true;
        mixpanel.track("Opened media source modal", {
            "Session ID": sessionId,
        });
    }
    const closeMediaSourceModal = () => {
        setMediaSourceModalIsOpen(false);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    }

    const closeGallaryUploadWidget = () => {
        setGallaryUploadModalIsOpen(false);
        setUploadModalsClosed(true);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    };

    const closeGoogleModal = () => {
        setGoogleModalIsOpen(false);
        setUploadModalsClosed(true);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    };

    const closeFacebookModal = () => {
        setFacebookModalIsOpen(false);
        setUploadModalsClosed(true);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    };

    const openChooseSelfieModal = () => {
        setChooseSelfieModalIsOpen(true);
        setMediaSourceModalIsOpen(false);
        window.disableBackgroundChange = true;
        mixpanel.track("Opened choose selfie modal", {
            "Session ID": sessionId,
        });
    }

    const closeChooseSelfieModal = () => {
        setChooseSelfieModalIsOpen(false);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    }

    const openCheckNofImagesModal = () => {
        setCheckNofImagesModalIsOpen(true);
        setMediaSourceModalIsOpen(false);
        window.disableBackgroundChange = true;
    }
    const closeCheckNofImagesModal = () => {
        if (userConnectedGooglePhotos && !enoughImagesFound) {
            setUserConnectedGooglePhotos(false);
        }
        if (userConnectedFacebook && !enoughImagesFound) {
            setUserConnectedFacebook(false);
        }
        setCheckNofImagesModalIsOpen(false);
        if (!enoughImagesFound) {
            setMediaSourceModalIsOpen(true);
        }
    }

    useEffect(() => {
        window.uploadModalOpen = openMediaSourceModal
    });

    useEffect(() => {
        if ((userConnectedGooglePhotos | userConnectedFacebook) && userUploadedSelfie === '') {
            if (enoughImagesFound) {
                openChooseSelfieModal();
            } else {
                openCheckNofImagesModal();
            }
        }
        else if (userConnectedFacebook && !enoughImagesFound) {
            openCheckNofImagesModal();
        }
    });

    useEffect(() => {
        if (imageUrlsToUpload.length > 0 && userUploadedSelfie !== '' && (userConnectedGooglePhotos | userConnectedFacebook) && sessionId && enoughImagesFound) {
            uploadImagesEndpoint(sessionId, imageUrlsToUpload, userUploadedSelfie);
            setUserUploadedImages(true);
        }
    }, [imageUrlsToUpload, userUploadedSelfie, userConnectedGooglePhotos, userConnectedFacebook, setUserUploadedImages, sessionId, enoughImagesFound]);


    return (
        <div>
            <div onClick={openMediaSourceModal}
                style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <img src='./cloud_upload.png' alt='Upload' style={{
                        width: '50px',
                        height: '50px',
                        opacity: isMobile ? 0.7 : 0.5
                    }} />
                    <p style={{ opacity: isMobile ? 0.9 : 0.8, margin: 0, fontWeight: 'bold' }}>
                        <span style={{ color: 'gold' }}>Upload</span> your images to start
                    </p>
                </div>
            </div>
            <Modal
                isOpen={gallaryUploadModalIsOpen}
                onRequestClose={closeGallaryUploadWidget}
                contentLabel="Upload Modal"
                appElement={document.getElementById('root')}
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        zIndex: 1000 // Ensuring the modal is above everything
                    },
                    content: {
                        position: 'fixed', // Changed from absolute to fixed to ensure it stays in place relative to the viewport
                        top: isMobile ? '60%' : '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '100%' : '50%',
                        height: isMobile ? '80%' : '60%',
                        border: 'transparent',
                        background: 'transparent',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                    }
                }}
            >
                <GallaryUploadWidget sessionId={sessionId} setUserUploadedImages={setUserUploadedImages} closeModal={closeGallaryUploadWidget} />
            </Modal>
            <Modal
                isOpen={googleModalIsOpen}
                onRequestClose={closeGoogleModal}
                contentLabel="Google Photos Upload Modal"
                appElement={document.getElementById('root')}
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        zIndex: 1000 // Ensuring the modal is above everything
                    },
                    content: {
                        position: 'fixed', // Changed from absolute to fixed to ensure it stays in place relative to the viewport
                        top: isMobile ? '60%' : '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '100%' : '50%',
                        height: isMobile ? '80%' : '60%',
                        border: 'transparent',
                        background: 'transparent',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                    }
                }}
            >
                <GooglePhotosLoginAndFetch sessionId={sessionId} closeModal={closeGoogleModal}
                    setUserConnectedGooglePhotos={setUserConnectedGooglePhotos} setImageUrlsToUpload={setImageUrlsToUpload} setEnoughImagesFound={setEnoughImagesFound} />
            </Modal>
            <Modal
                isOpen={facebookModalIsOpen}
                onRequestClose={closeFacebookModal}
                contentLabel="Facebook Upload Modal"
                appElement={document.getElementById('root')}
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        zIndex: 1000 // Ensuring the modal is above everything
                    },
                    content: {
                        position: 'fixed', // Changed from absolute to fixed to ensure it stays in place relative to the viewport
                        top: isMobile ? '60%' : '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '100%' : '50%',
                        height: isMobile ? '80%' : '60%',
                        border: 'transparent',
                        background: 'transparent',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                    }
                }}
            >
                <FacebookPhotosLoginAndFetch sessionId={sessionId} closeModal={closeFacebookModal}
                    setImageUrlsToUpload={setImageUrlsToUpload} setUserConnectedFacebook={setUserConnectedFacebook} setEnoughImagesFound={setEnoughImagesFound} />
            </Modal>
            <CheckNofImagesModal isOpen={checkNofImagesModalIsOpen}
                onRequestClose={closeCheckNofImagesModal}
                enoughImagesFound={enoughImagesFound}
                userConnectedGooglePhotos={userConnectedGooglePhotos}
                userConnectedFacebook={userConnectedFacebook}
            />
            <ChooseSelfieModal isOpen={chooseSelfieModalIsOpen}
                onRequestClose={closeChooseSelfieModal}
                setUserUploadedSelfie={setUserUploadedSelfie}
                sessionId={sessionId}
            />
            <MediaSourceModal isOpen={mediaSourceModalIsOpen}
                onRequestClose={closeMediaSourceModal}
                openGallaryModal={openGallaryUploadWidget}
                openGoogleModal={openGoogleModal}
                openFacebookModal={openFacebookModal} />
        </div>
    )
}

export default UploadButton;