import React, { Fragment } from "react";
import {
	Nav,
	NavContainer,
	NavLogo,
	NavItem,
	NavMenu,
} from "./NavbarStyles";
import "./Navbar.css";

const Navbar = () => {
	return (
		<Fragment>
			<Nav
				className={"navbar"}
			>
				<NavContainer>
					<NavLogo href="#" style={{ paddingTop: "0px" }}>
					</NavLogo>
					<NavMenu>
						<NavItem>
						</NavItem>
					</NavMenu>
				</NavContainer>
			</Nav>
		</Fragment>
	);
};

export default Navbar;

