import { datadogLogs } from '@datadog/browser-logs';
import useServerConfig from '../../../../../utils/useServerConfig';

const FacebookPhotosLoginAndFetch = ({ sessionId, closeModal, setImageUrlsToUpload, setUserConnectedFacebook, setEnoughImagesFound }) => {
    const numImagesToFetch = 70;
    const serverConfig = useServerConfig();

    const fetchFacebookPhotos = async (fbUserId, accessToken) => {
        try {
            setUserConnectedFacebook(true);
            closeModal();
            let imageIds = [];
            let currentYear = new Date().getFullYear();
            const twentyYearsAgo = currentYear - 20;
            const nofRequiredImages = serverConfig?.upload_widget?.nof_required_images;
            const fetchPhotos = async (year) => {
                let idArray = [];
                let apiUrl = `/${fbUserId}/photos?limit=${numImagesToFetch}&since=${year}-01-01&until=${year}-12-31&access_token=${accessToken}`;
                await new Promise((resolve, reject) => {
                    window.FB.api(apiUrl, function (fbResponse) {
                        if (fbResponse && !fbResponse.error) {
                            idArray = [...idArray, ...fbResponse.data.map(image => image.id)];
                            resolve();
                        } else if (fbResponse.error) {
                            datadogLogs.logger.error('Error fetching Facebook Photos', { 'error-message': fbResponse.error.message, 'hue-session-id': sessionId, 'error_type': 'fetch_error' });
                            reject();
                        }
                    });
                });
                return idArray;
            }

            while ((currentYear > twentyYearsAgo) && (imageIds.length < numImagesToFetch)) {
                let currentYearsPhotos = await fetchPhotos(currentYear);
                imageIds = [...imageIds, ...currentYearsPhotos];
                currentYear--;
            }
            // TODO: omer -> display a message to the user
            if (imageIds.length < nofRequiredImages) {
                datadogLogs.logger.info('Less than 15 photos were fetched from facebook', { 'hue-session-id': sessionId, 'user_id': fbUserId });
                setEnoughImagesFound(false);
            }
            imageIds = imageIds.slice(0, numImagesToFetch);
            let imageUrls = [];
            let urlPromises = imageIds.map(imageId => {
                return new Promise((resolve, reject) => {
                    window.FB.api(`/${imageId}/picture?redirect=false&access_token=${accessToken}`, function (response) {
                        if (response && !response.error) {
                            imageUrls.push(response.data.url);
                            resolve();
                        } else {
                            reject(new Error('Error while fetching image URL'));
                            setEnoughImagesFound(false);
                        }
                    });
                });
            });
            await Promise.all(urlPromises)
                .then(() => {
                    setImageUrlsToUpload(imageUrls);
                    setEnoughImagesFound(true);
                });
        }
        catch (error) {
            let errorMessage = 'Error while fetching facebook photos';
            if (error) {
                errorMessage = error.message;
            }
            datadogLogs.logger.error('Error while fetching facebook photos', { 'error-message': errorMessage, 'hue-session-id': sessionId, 'user_id': fbUserId, 'error_type': 'fetch_error' });
            setEnoughImagesFound(false);
        }
    };

    const handleFacebookLogin = async () => {
        if (window.FB) {
            window.FB.login((response) => {
                if (response.authResponse) {
                    let fbUserId = response.authResponse.userID;
                    let accessToken = response.authResponse.accessToken;
                    fetchFacebookPhotos(fbUserId, accessToken);
                }
            }, { scope: ['public_profile', 'user_photos'] });
        } else {
            datadogLogs.logger.error('Facebook SDK not initialized');
        }
    };

    handleFacebookLogin();

    return null;
};

export default FacebookPhotosLoginAndFetch;
