import { useState, useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import SessionSelection from './SessionSelection';
import { getResizedImagesUrls, getImagesRating } from '../utils/jsUtils';
import useServerConfig from '../utils/useServerConfig';
import UserPhotos from './UserPhotos';
import {
    AggregatedImageStarsChart, SimilarityNearestScoresChart, ImageStarsByEpochChart,
    ImageStarsByCFGChart, PickScoresChart, SimilarityAvgScoresChart, SimilarityMaxDistScoresChart
} from './charts/MongoCharts';
import SignIn from '../signup/SignupButton'
import SigninMethodsWidget from '../signup/SigninMethodsWidget';
import ActiveSessionsChart from './ActiveSessions';

const adminUploadedImagesEndpoint = "/admin/get_uploaded_session_images"
const adminGeneratedImagesEndpoint = "/admin/get_generated_session_images"
const adminProcessedImagesEndpoint = "/admin/get_processed_session_images"

const AdminPage = () => {
    const [sessionId, setSessionId] = useState('');
    const [sessionGeneratedImagesUrls, setSessionGeneratedImagesUrls] = useState([]);
    const [sessionGeneratedImagesIds, setSessionGeneratedImagesIds] = useState([]);
    const [sessionUploadedImagesUrls, setSessionUploadedImagesUrls] = useState([]);
    const [sessionProcessedImagesUrls, setSessionProcessedImagesUrls] = useState([]);
    const [generatedImagesRating, setGeneratedImagesRating] = useState({});
    const [googleAuthToken, setGoogleAuthToken] = useState(null);
    const config = useServerConfig();

    useEffect(() => {
        // Extract session_id from URL
        const urlParams = new URLSearchParams(window.location.search);
        const sessionFromUrl = urlParams.get('session_id');
        if (sessionFromUrl) {
            setSessionId(sessionFromUrl);
        }
    }, []);


    useEffect(() => {
        if (sessionId && config && Object.keys(config).length > 0 && googleAuthToken) {
            getResizedImagesUrls(sessionId, adminGeneratedImagesEndpoint, 512, 512, googleAuthToken).then((urls) => {
                if (urls) {
                    const urlsToShow = urls.session_image_urls;
                    const idsToShow = urls.session_images_ids;
                    setSessionGeneratedImagesUrls(urlsToShow);
                    setSessionGeneratedImagesIds(idsToShow);
                    getImagesRating(idsToShow).then((ratings) => {
                        setGeneratedImagesRating(ratings);
                    });
                }
            });
            getResizedImagesUrls(sessionId, adminUploadedImagesEndpoint, 512, 512, googleAuthToken).then((urls) => {
                if (urls) {
                    setSessionUploadedImagesUrls(urls);
                }
            });
            getResizedImagesUrls(sessionId, adminProcessedImagesEndpoint, 512, 512, googleAuthToken).then((urls) => {
                if (urls) {
                    setSessionProcessedImagesUrls(urls);
                }
            });
        }
    }, [sessionId, googleAuthToken, config]);

    const onSessionSelect = (sessionId) => {
        // reset current session images
        setSessionGeneratedImagesUrls([]);
        setSessionGeneratedImagesIds([]);
        setSessionUploadedImagesUrls([]);
        setSessionProcessedImagesUrls([]);
        setGeneratedImagesRating({});
        setSessionId(sessionId);
    }

    return (
        <div> {googleAuthToken ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100vw' }}>
                <div>
                    <h1>Admin page</h1>
                </div>
                <div style={{
                    margin: '20px',
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#101215',
                    zIndex: '100'
                }}>
                    <SessionSelection sessionId={sessionId} onSessionSelect={onSessionSelect} googleAuthToken={googleAuthToken} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignContent: 'center',
                    gap: '20px',
                    width: '100%'
                }}>
                    {/* Uploaded Images */}
                    <ActiveSessionsChart googleAuthToken={googleAuthToken} />
                    <h2 style={{ margin: '20px 0' }}>Uploaded Images</h2>
                    {sessionUploadedImagesUrls && sessionUploadedImagesUrls.length > 0 && <UserPhotos key={sessionId + "-uploaded"} imageUrls={sessionUploadedImagesUrls} />}
                    {/* Processed Images */}
                    <h2 style={{ margin: '20px 0' }}>Processed Images</h2>
                    {sessionProcessedImagesUrls && sessionProcessedImagesUrls.length > 0 && <UserPhotos key={sessionId + "-processed"} imageUrls={sessionProcessedImagesUrls} />}
                    {/* Generated Images */}
                    <h2 style={{ margin: '20px 0' }}>Generated Images</h2>
                    {sessionGeneratedImagesUrls && sessionGeneratedImagesUrls.length > 0 && <UserPhotos key={sessionId + "-generated"}
                        imageUrls={sessionGeneratedImagesUrls} imageIds={sessionGeneratedImagesIds} imageRating={generatedImagesRating} />}
                    <h2 style={{ margin: '20px 0' }}>Session Charts</h2>
                    {sessionId !== '' && (
                        <div>
                            <div style={{ width: '80vw', height: '50vh', display: 'flex', flexDirection: 'row', gap: '40px' }}>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <AggregatedImageStarsChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <SimilarityNearestScoresChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <ImageStarsByEpochChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                            </div>
                            <div style={{ width: '80vw', height: '50vh', display: 'flex', flexDirection: 'row', gap: '40px' }}>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <SimilarityAvgScoresChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <SimilarityMaxDistScoresChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <PickScoresChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                            </div>
                            <div style={{ width: '80vw', height: '50vh', display: 'flex', flexDirection: 'row', gap: '40px' }}>
                                <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
                                    <ImageStarsByCFGChart width={'100%'} height={'100%'} session_id={sessionId} />
                                </div>
                            </div>
                        </div>
                    )}
                    <a href="https://charts.mongodb.com/charts-project-0-rjnbwzu/public/dashboards/662ecc39-a821-4d74-8c15-a10232f65d4d"
                        target="_blank" rel="noreferrer" style={{ margin: '20px 0', color: '#FFFFFF', textDecoration: 'none' }}>
                        <h2>Go to Selfyx Dashboard</h2>
                    </a>
                </div>
            </div> :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <SignIn />
                <SigninMethodsWidget sessionId={sessionId} setGoogleAuthToken={setGoogleAuthToken} />
            </div>
        }
        </div>
    );
};

export default AdminPage;
