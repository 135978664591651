import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'primereact/button';
import './CheckNofImagesModal.css';
import Loader from 'react-loaders';

const CheckNofImagesModal = ({ isOpen, onRequestClose, enoughImagesFound, userConnectedGooglePhotos, userConnectedFacebook }) => {
    const [isLoading, setIsLoading] = useState(true);
    const imageProvider = userConnectedGooglePhotos ? 'Google Photos' : userConnectedFacebook ? 'Facebook' : 'Instagram';

    useEffect(() => {
        if (enoughImagesFound) {
            onRequestClose();
        }
        else if (enoughImagesFound === false) {
            setIsLoading(false);
        }
    }, [enoughImagesFound, onRequestClose]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Check nof images modal"
            appElement={document.getElementById('root')}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    border: '1px solid #ccc',
                    background: '#101215',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '10px',
                    outline: 'none',
                    padding: '20px'
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', textAlign: 'center' }}>
                {isLoading ? <Loader type="ball-pulse" active /> :
                    <div>
                        <div>
                            <h2>Not enough images</h2>
                        </div>
                        <div>We did not find enough images on {imageProvider}<br />
                            Please choose another image provider
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <Button icon="pi pi-arrow-left" label="Go back" style={{ width: '40%' }} outlined className="media-button"
                                onClick={() => onRequestClose()} />
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default CheckNofImagesModal;
