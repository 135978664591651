import React from 'react';
import Modal from 'react-modal';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import './MediaSourceModal.css';
import useServerConfig from '../../../../../utils/useServerConfig';

const MediaSourceModal = ({ isOpen, onRequestClose, openGallaryModal, openGoogleModal, openFacebookModal }) => {
    const serverConfig = useServerConfig();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Email Form"
            appElement={document.getElementById('root')}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    border: '1px solid #ccc',
                    background: '#101215',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '10px',
                    outline: 'none',
                    padding: '20px'
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <div>
                    <h2>Choose images source</h2>
                    <p>We need {serverConfig?.upload_widget?.nof_required_images} images of you</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', width: '96%' }}>
                    <Tooltip target=".disabled-btn" content="Coming Soon!" position="bottom" />
                    <Button icon="pi pi-facebook" label="Auto Fetch from Facebook" style={{ width: '100%', opacity: '0.5'  }} outlined className="media-button disabled-btn" />
                    <Button icon="pi pi-google" label="Auto Fetch from Google Photos" style={{ width: '100%', opacity: '0.5'  }} outlined className="media-button disabled-btn" />
                    <Button icon="pi pi-instagram" label="Auto Fetch from Instagram" style={{ width: '100%', opacity: '0.5' }} outlined className="media-button disabled-btn" />
                    <Button icon="pi pi-images" label="Upload From Gallery" style={{ width: '100%' }} outlined className="media-button" onClick={openGallaryModal} />
                </div>
            </div>
        </Modal>
    );
};

export default MediaSourceModal;

