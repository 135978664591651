import { api_server_url } from "../utils/constants";
import mixpanel from 'mixpanel-browser';

const SeeMoreImagesButton = ({ sessionId }) => {
    const handleButtonClick = () => {
        mixpanel.track("SeeMoreImagesButton clicked", {
            "Session ID": sessionId,
        });
    };

    return <div style={{
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'white',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    }}>
        <form action={`${api_server_url}/stripe/create-checkout-session?session_id=${sessionId}`} method="POST">
            <button
                type="submit"
                style={{
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    border: '1px solid #ffffff',
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: '18px',
                    padding: '10px 10px',
                    textAlign: 'center',
                    fontWeight: 'bold'
                }}
                onClick={handleButtonClick}
            >See More Images</button> &nbsp;
        </form>
    </div>
};

export default SeeMoreImagesButton;
