import mixpanel from 'mixpanel-browser';
import { datadogLogs } from '@datadog/browser-logs';
import useServerConfig from '../../../../../utils/useServerConfig';
import { useEffect } from 'react';

const GooglePhotosLoginAndFetch = ({ sessionId, setUserConnectedGooglePhotos, closeModal, setImageUrlsToUpload, setEnoughImagesFound }) => {
    const serverConfig = useServerConfig();
    useEffect(() => {
        const google = window.google;
        const google_oauth_client_id = process.env.REACT_APP_GOOGLE_OAUTO_CLIENT_ID;
        const nofRequiredImages = serverConfig?.upload_widget?.nof_required_images;
        const maxImagesToFetch = serverConfig?.upload_widget?.max_nof_google_images;
        const imagesPrePage = 100;
        const nofPagesToFetch = Math.ceil(maxImagesToFetch / imagesPrePage);

        const client = google.accounts.oauth2.initTokenClient({
            client_id: google_oauth_client_id,
            scope: 'https://www.googleapis.com/auth/photoslibrary.readonly',
            callback: async (response) => {
                try {
                    fetchGooglePhotos(response.access_token);
                } catch (error) {
                    datadogLogs.logger.error('Error while logging in with google', { 'error-message': error.message, 'hue-session-id': sessionId, 'error_type': 'login_error' });
                }
            },
        });


        const fetchGooglePhotos = async (accessToken) => {
            try {
                mixpanel.track("Google Upload Images", {
                    "Session ID": sessionId,
                });
                // the user completed the deed
                // its possible that he does not have any photos in his google photos
                // we will have to treat this case later
                setUserConnectedGooglePhotos(true);
                closeModal();
                let nextPageToken = '';
                let fetchedPhotos = [];
                let imageIds = [];
                for (let i = 0; i < nofPagesToFetch; i++) {
                    const res = await fetch(`https://photoslibrary.googleapis.com/v1/mediaItems?pageSize=${imagesPrePage}&pageToken=${nextPageToken}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    const data = await res.json();
                    if (res.status === 401) {
                        throw new Error(data.message);
                    }
                    if (!data.mediaItems || data.mediaItems.length === 0) {
                        break;
                    }
                    fetchedPhotos = [...fetchedPhotos, ...data.mediaItems];
                    imageIds = [...imageIds, ...data.mediaItems.map(item => item.id)];
                    nextPageToken = data.nextPageToken;
                    if (fetchedPhotos.length >= nofRequiredImages) {
                        // this will allow the user to proceeed and upload a selfie
                        setEnoughImagesFound(true);
                    }
                    if (!nextPageToken) {
                        break;
                    }
                }
                if (fetchedPhotos.length < nofRequiredImages) {
                    // this will prompt the user to use a different method
                    setEnoughImagesFound(false);
                }
                datadogLogs.logger.info('Fetched Google Photos', { 'num_photos': fetchedPhotos.length, 'hue-session-id': sessionId });
                fetchedPhotos = fetchedPhotos.slice(0, maxImagesToFetch);
                const baseUrls = fetchedPhotos.map(photo => `${photo.baseUrl}=w512-h512`);
                setImageUrlsToUpload(baseUrls);
            } catch (error) {
                datadogLogs.logger.error('Error fetching Google Photos', { ' or-message': error.message, 'hue-session-id': sessionId, 'error_type': 'fetch_error' });
            }
        };

        client.requestAccessToken();
    }, [sessionId, setUserConnectedGooglePhotos, closeModal, setImageUrlsToUpload, setEnoughImagesFound, serverConfig]);

    return null;
};

export default GooglePhotosLoginAndFetch;
