import React from 'react';
import { gsap } from 'gsap';
import { datadogLogs } from '@datadog/browser-logs';
import mixpanel from 'mixpanel-browser';
import './ConsentPopup.css';

const ConsentPopup = ({ sessionId, setConsent, modalIsOpen, setModalIsOpen }) => {
  const isMobile = window.innerWidth < 500;
  const consentRef = React.useRef(null);

  const handleAccept = () => {
    setConsent(true);
    setModalIsOpen(false);
    mixpanel.track("Consent Accepted", {
        "Session ID": sessionId,
    });
    datadogLogs.logger.info('Consent Accepted', { 'hue-session-id': sessionId });
  };

  const handleReject = () => {
    setConsent(false);
    setModalIsOpen(false);
    mixpanel.track("Consent Rejected", {
        "Session ID": sessionId,
    });
    datadogLogs.logger.info('Consent Rejected', { 'hue-session-id': sessionId });
  };

  React.useEffect(() => {
    if (modalIsOpen) {
      gsap.fromTo(consentRef.current, { y: '100%' }, { y: '0%', duration: 1 });
    }
  }, [modalIsOpen]);

  return (
    <div>
    {modalIsOpen && <div
        ref={consentRef} 
        className={`consent-banner ${modalIsOpen ? 'show' : ''}`}
        style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            maxHeight: isMobile ? '50%' : '30%',
            backgroundColor: 'rgba(16, 18, 21, 0.7)',
            color: '#fff',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            zIndex: '100000',
            overflowY: 'scroll'
        }}
        >
        <h2 style={{ marginBottom: '0px' }}>We value your privacy</h2>
        <p>We use cookies and personal data such as device type and location to provide a better experience.</p>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button className="consent-popup-button" style={{ padding: '10px 20px', marginRight: '10px', fontWeight: 'bold' }} onClick={handleAccept}>Accept</button>
            <button className="consent-popup-button" style={{ padding: '10px 20px', marginLeft: '10px', fontWeight: 'bold' }} onClick={handleReject}>Reject</button>
        </div>
        </div>}
    </div>
  );
};
export default ConsentPopup;
