import { useState, useEffect } from "react";

// Custom hook to get window size
const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
        const updateSize = () => {
            // change size only if there is a 10% difference in screen size
            const newSize = [window.innerWidth, window.innerHeight];
            if (Math.abs(size[0] - newSize[0]) > size[0] * 0.2 || Math.abs(size[1] - newSize[1]) > size[1] * 0.2) {
                setSize(newSize);
            }
        };
        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, [size]);

    return size;
};

export default useWindowSize;