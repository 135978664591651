import TextSection from "../TextSection";
import UploadSection from "./upload/UploadSection";
import GlowingButton from "../../../buttons/GlowingButton";

const TryIt = ({ sessionId, userUploadedImages, setUserUploadedImages }) => {
    const isMobile = window.innerWidth <= 500;
    return (
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', backgroundColor: '#101215', width: '100vw', alignItems: 'center' }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: isMobile ? '30vh' : '90vh',
                width: isMobile ? '95vw' : '40vw'
            }}>
                <TextSection header={"Rediscover yourself"}
                    text={"Selfyx is very easy to use, try it now for free!"} />
                {!isMobile && <div style={{ paddingTop: '20px' }}>
                    <GlowingButton text="Try it now &#x2192;" onClick={() => window.uploadModalOpen()}></GlowingButton>
                </div>}
            </div>
            <div style={{
                height: isMobile ? '70vh' : '90vh',
                width: isMobile ? '95vw' : '60vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '30px'
            }}>
                <UploadSection vw={isMobile ? 90 : 50} vh={isMobile ? 60 : 48} sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} />
            </div>
        </div>
    );
};


export default TryIt;