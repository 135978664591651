import ImageSSIM from './image-ssim'

function loadImage(path, index, done, maxSize = 500) { // maxSize parameter with default value
    var img = new Image();
    img.onload = function () {
        // resize images to maxSize to reduce memory pressure and computation times
        let imageWidth = img.naturalWidth;
        let imageHeight = img.naturalHeight;
        if (imageWidth > maxSize || imageHeight > maxSize) {
            const aspectRatio = imageWidth / imageHeight;
            
            if (imageWidth > imageHeight) {
                imageWidth = maxSize;
                imageHeight = Math.round(imageWidth / aspectRatio);
            } else {
                imageHeight = maxSize;
                imageWidth = Math.round(imageHeight * aspectRatio);
            }
        }
            
        // Create and resize canvas
        var canvas = document.createElement('canvas');
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight); // Draw the image at the new dimensions
        var id = ctx.getImageData(0, 0, imageWidth, imageHeight);

        done({
            width: imageWidth,
            height: imageHeight,
            data: id.data,
            channels: 4,
            canvas: canvas,
            dispose: function() {
                canvas.width = 0;
                canvas.height = 0;
                canvas = null; // Help the garbage collector by dereferencing the canvas
            }
        }, index);
    };
    img.src = path;
}

function loadImagePair(pair, done, maxSize) {
    var images = [];

    function loaded(img, index) {
        images[index] = img;

        if (images[0] && images[1]) {
            done(images);
        }
    }

    loadImage(pair[0], 0, loaded, maxSize);
    loadImage(pair[1], 1, loaded, maxSize);
}

function compareImagePair(pair, maxSize) {
    return new Promise((resolve, reject) => {
        loadImagePair(pair, function (images) {
            var log = {};
            log.windowSize = pair[2];
            log.luminance = pair[3];
            var start = new Date().getTime();
            var res;
            if (images[0].width !== images[1].width || images[0].height !== images[1].height) {
                res = { ssim: 0 };
            } else {
                res = ImageSSIM.compare(images[0], images[1], pair[2], 0.01, 0.03, pair[3]);
            }
            log.ssim = Math.round(res.ssim * 1000) / 1000;
            log.ellapsed = new Date().getTime() - start + 'ms';

            resolve(log.ssim); // Resolve the promise with the SSIM result

            // Dispose of the canvases
            images.forEach(image => image.dispose());
        }, maxSize);
    });
}

const isDuplicate = (filePath, validFilePaths, ssimThreshold, maxSize) => {
    let comparisons = validFilePaths.map(validPath => {
        let pair = [filePath, validPath];
        return compareImagePair(pair, maxSize); // Returns a Promise
    });

    return Promise.all(comparisons).then(results => {
        return results.some(ssim => ssim > ssimThreshold); // Check if any SSIM value exceeds the threshold
    });
}

export default isDuplicate;
