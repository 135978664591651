import React, { useEffect, useState } from 'react';
import BoxedImage from './BoxedImage';

function ImagePalette({ imageGroups, vh, vw }) {
    const [groupIndex, setGroupIndex] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [fade, setFade] = useState(false);
    let durationOfFadeEffect = 300;
    let timeoutDuration = 3000;
    const isMobile = window.innerWidth < 500;
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            setFade(true);
            setTimeout(() => {
                if (isMobile) {
                    setImageIndex((prev) => (prev + 1) % imageGroups[groupIndex].length);
                    if (imageIndex === imageGroups[groupIndex].length - 1) {
                        setGroupIndex((prev) => (prev + 1) % imageGroups.length);
                    }
                } else {
                    setGroupIndex((prev) => (prev + 1) % imageGroups.length);
                }
                setFade(false);
            }, durationOfFadeEffect);
        }, timeoutDuration);

        return () => clearTimeout(timeout);
    }, [groupIndex, imageIndex, imageGroups, isMobile, durationOfFadeEffect, timeoutDuration]);
    const adjustedVw = isMobile ? vw : vw / imageGroups[0].length;

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            {
                isMobile ? 
                <div style={{ transition: 'opacity 0.7s', opacity: fade ? 0 : 1 }}>
                    <BoxedImage vh={vh} vw={adjustedVw} imageId={imageGroups[groupIndex][imageIndex]} />
                </div>
                :
                imageGroups[groupIndex].map((imageId, index) => (
                    <div style={{ marginRight: index !== imageGroups[groupIndex].length - 1 ? '20px' : '0', transition: 'opacity 0.5s', opacity: fade ? 0 : 1 }}>
                        <BoxedImage vh={vh} vw={adjustedVw} imageId={imageId} />
                    </div>
                ))
            }
        </div>
    );
}

export default ImagePalette;
