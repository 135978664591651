import React from 'react';
import './PolicyAndTerms.css';


const TermsOfService = () => {
    return (
        <div className="terms-of-service">
            <div class="MarkdownStyles_markdown-legal__4sC8d undefined">
                <h1 class="text-center pb-8">Terms of Service</h1>
                <p>Effective Date: <strong>5/10/2024</strong></p>
                <p>Thank you for choosing Selfyx by Eikona Technologies Inc. ("Selfyx", "we", "us"). This document, along with related agreements including our <a href="privacy-policy">Privacy Policy</a>, governs your use of www.selfyx.com, related mobile and software applications, and other services we provide (collectively, the "Service"). By clicking "I Accept", downloading, installing, or otherwise using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service ("Terms").</p>
                <h3>Legal Agreement</h3>
                <p>By using the Service, you enter into a legally binding contract with us. If you do not agree to these Terms, you are not permitted to use the Service. Your use of the Service constitutes your agreement to these Terms.</p>
                <h3>Arbitration Notice</h3>
                <p>By agreeing to these Terms, you waive your right to a jury trial and agree to resolve any disputes through binding arbitration, as detailed in Section 17 ("Dispute Resolution and Arbitration"). This excludes specific disputes that are provided for differently in Section 17 and means you waive any right to participate in a class action lawsuit or class-wide arbitration.</p>
                <h3>1. Service Overview</h3>
                <p>Selfyx provides innovative tools for creating personalized visual content using artificial intelligence ("AI"). These tools allow users to upload images and customize outputs with various options for backgrounds, poses, and styles.</p>
                <h3>2. Eligibility</h3>
                <p>To use the Service, you must be at least 18 years old. You represent that you are of legal age, have not been previously suspended from the Service, and that your use complies with all applicable laws and regulations. If you are accepting these Terms on behalf of an entity, you represent that you have the authority to bind that entity.  The Service is intended to be used solely for creating photos of yourself. It is prohibited to use the Service to generate photos of other individuals.</p>
                <h3>3. Accounts and Registration</h3>
                <p>To access most features of the Service, you may need to register and create an account. By linking a third-party account, you authorize us to access and use your information from these accounts to enhance your experience on our Service.</p>
                <h3>4. Payments</h3>
                <p>Certain features of the Service may require payment. You will be informed of any fees and must agree to them prior to being charged. All fees are non-refundable and charged in U.S. dollars. Promotional offers may vary and are not part of the Terms unless explicitly provided.</p>
                <h3>5. Licenses and Intellectual Property</h3>
                <p>We grant you a limited, non-exclusive license to use the Service for your personal, non-commercial use. The intellectual property of the Service and any content created or provided remains with us or our licensors, except for the user-generated content you create, which you own.</p>
                <h3>6. User Content</h3>
                <p>You may post content to the Service. By doing so, you grant Selfyx a license to use, modify, and display that content in connection with the Service. You are responsible for ensuring that you have all the rights necessary to post such content and that it does not violate any laws or rights of others.</p>
                <h3>7. Prohibited Conduct</h3>
                <p>You agree not to use the Service for any unlawful purposes, to infringe on the rights of others, or to disrupt the Service or others' use of the Service. Creating or distributing nude or pornographic imagery is strictly prohibited. It is also illegal and subject to legal action to use the Service to create images of other individuals without their explicit consent. Furthermore, using this Service for or by individuals under the age of 18, even with consent, is prohibited and illegal.</p>
                <h3>8. Notice Regarding Content</h3>
                <p>Due to the inherent randomness of the AI process, some images generated by the Service may occasionally exhibit a nature that could be perceived as sensual or mildly provocative. These might include individuals in partial attire, with facial expressions or poses that could be interpreted as alluring. Full nudity or explicit sexual content is very unlikely and not an anticipated outcome. By using the Service, you acknowledge and accept the possibility of such results.</p>
                <h3>9. Amendments to the Terms</h3>
                <p>We may amend these Terms from time to time. Continued use of the Service after changes means you accept those changes. Check these Terms regularly for updates.</p>
                <h3>10. Termination and Modification of the Service</h3>
                <p>We may terminate your access to the Service or modify the Service at our discretion without notice. Upon termination, you must cease all use of the Service and any rights granted to you will end.</p>
                <h3>11. General Legal Terms</h3>
                <p>These Terms are governed by the laws of the state of Israel. Disputes arising hereunder will be resolved by binding arbitration in Israel. If any part of these Terms is found to be unenforceable, the remainder will continue in effect.</p>
                <h3>12. Contact Information</h3>
                <p>For questions or concerns about the Service, please contact us at <a href="mailto:support@eikona.io">support@eikona.io</a>.</p>
                <footer>
                <p>By using www.selfyx.com, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and any future modifications. If you do not agree to the Terms, you should not use Selfyx.</p>
                </footer>
            </div>
        </div>
    );
};

export default TermsOfService;

