import { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { api_server_url } from '../utils/constants';
import { datadogLogs } from '@datadog/browser-logs'


function SigninMethodsWidget({ sessionId, setGoogleAuthToken }) {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const isMobile = window.innerWidth <= 500;
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const toggleVisibility = (state) => {
            setIsVisible(state);
        };

        window.signinMethodsWidgetToggle = toggleVisibility;
    }, []);

    const onGoogleLoginSuccess = async (response) => {
        try {
            const res = await fetch(`${api_server_url}/google_login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    credential: response.credential,
                    clientId: response.clientId,
                    sessionId: sessionId
                }),
            });
            const data = await res.json();
            setUserName(data.name);
            datadogLogs.logger.info('Google login success', { 'data': data, 'hue-session-id': sessionId });
            if (data.authenticated) {
                setIsLoggedIn(true);
                setGoogleAuthToken(response.credential);
            }
        } catch (error) {
            datadogLogs.logger.error('Error while logging in with google', { 'error-message': error.message, 'hue-session-id': sessionId, 'error_type': 'login_error' });
        }
    };
    const errorMessage = (error) => {
        datadogLogs.logger.error('Error while logging in with google', { 'error-message': error.message, 'hue-session-id': sessionId, 'error_type': 'login_error' });
    };

    if (!isVisible) return null;
    return (
        <div style={{
            position: 'fixed',
            top: isMobile ? '82%' : '80%',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 1000,
            opacity: 0.83,
            maxWidth: isMobile ? '80vw' : '400px'
        }}>
            {!isLoggedIn && <GoogleLogin onSuccess={onGoogleLoginSuccess} onError={errorMessage} locale="en-US" />}
            {isLoggedIn &&
                <div style={{ fontSize: '15px', fontWeight: 'normal', color: 'black', width: '80vw', textAlign: 'center' }}>
                    Thank you {userName} for signing in.
                    <br />
                    You will be redirected to the admin page shortly.
                </div>}
        </div>
    )
}

export default SigninMethodsWidget;