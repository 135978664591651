import mixpanel from 'mixpanel-browser';

function SignupButton({ sessionId }) {

    const onSignupClick = () => {
        mixpanel.track("Signup button clicked", {
            "Session ID": sessionId,
        });
        window.signinMethodsWidgetToggle(true)
    }
    return (
        <>
            <div style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'white',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
            }}>

                <button onClick={onSignupClick} style={{
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    border: '1px solid #ffffff',
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: '20px',
                    padding: '10px 10px',
                    textAlign: 'center'
                }}>Sign in</button> &nbsp;&nbsp;&nbsp;to view admin page
            </div>
        </>
    )
}

export default SignupButton;