import { Navigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useState, useEffect } from 'react';

const GeneratedImagesEmail = () => {
    const [sessionId, setSessionId] = useState('');
    mixpanel.identify(sessionId)
    mixpanel.track('Get images by mail', {
        'Session ID': sessionId
    });
    useEffect(() => {
        // Extract session_id from URL
        const urlParams = new URLSearchParams(window.location.search);
        const sessionFromUrl = urlParams.get('session_id');
        if (sessionFromUrl) {
            setSessionId(sessionFromUrl);
        }
    }, []);

    return sessionId && <Navigate to={`/generated-images?session_id=${sessionId}`} />;
};

export default GeneratedImagesEmail;
