import React from 'react';
import { Helmet } from 'react-helmet';
import '../../App.css';
import ChangingBackground from './facade/ChangingBackground';
import { Element } from 'react-scroll';
import { useRef, useEffect, useState } from 'react';
import Intro from './intro/Intro';
import SigninMethodsWidget from '../signup/SigninMethodsWidget';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';

const HomePage = ({ sessionId, userUploadedImages, setUserUploadedImages, finishedTyping, setFinishedTyping }) => {
    const shiningStarsCanvasRef = useRef(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const isMobile = window.innerWidth <= 500;

    // shining stars animation
    useEffect(() => {
        const canvas = shiningStarsCanvasRef.current;
        if (canvas) {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#101215';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const gridWidth = 10;
            const gridHeight = 10;

            for (let i = 0; i < gridWidth; i++) {
                for (let j = 0; j < gridHeight; j++) {
                    const x = (i + Math.random()) * (window.innerWidth / gridWidth);
                    const y = (j + Math.random()) * (window.innerHeight / gridHeight);
                    ctx.fillStyle = '#fff';
                    ctx.shadowColor = '#fff';
                    ctx.shadowBlur = 10;
                    ctx.beginPath();
                    ctx.arc(x, y, isMobile ? 2 : 3, 0, Math.PI * 2);
                    ctx.fill();
                }
            }
            setBackgroundImage(canvas.toDataURL());
        }
    }, [isMobile]);

    return (
        <div className="Homepage">
            <Helmet>
                <title>AI Selfie Generator - Create Personalized AI Images</title>
                <meta name="description" content="Create personalized AI selfies and avatars with our free AI selfie generator. Try it now!" />
                <meta name="keywords" content="selfie generator, ai generated selfie, ai selfie generator, free ai selfie generator, photo ai, photoai, photosai, secta, sectalabs, secta labs, aragon, aragon ai, ai selfie, ai portrait, facetune, ai selfies, personalized ai, personalized diffusion model, ai avatar, ai profile picture, ai generated photos, create images with ai, topaz photo ai, nearby me photo studio, pic studio near me, portrait studio close to me" />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="AI Selfie Generator - Create Personalized AI Images!" />
                <meta property="og:description" content="Create personalized AI selfies and avatars with our free AI selfie generator. Try it now!" />
                <meta property="og:image" content="https://selfyx.com/camera.png" />
                <meta property="og:url" content="https://selfyx.com" />
                <meta property="og:type" content="website" />
                
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="AI Selfie Generator - Create Personalized AI Images" />
                <meta name="twitter:description" content="Create personalized AI selfies and avatars with our free AI selfie generator. Try it now!" />
                <meta name="twitter:image" content="https://selfyx.com/camera.png" />

                {/* Some more meta tags */}
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Eikona Technologies Inc." />
                <meta itemprop="name" content="AI Selfie Generator - Create Personalized AI Images" />
                <meta itemprop="description" content="Create personalized AI selfies and avatars with our free AI selfie generator. Try it now!" />
                <meta itemprop="image" content="https://selfyx.com/camera.png" />
                <meta name="pinterest-rich-pin" content="true" />

            </Helmet>
            <Navbar />
            <Element name="background" className="element">
                <ChangingBackground finishedTyping={finishedTyping} setFinishedTyping={setFinishedTyping} />
            </Element>
            <Element >
                {/* this is just to render the stars background */}
                <canvas ref={shiningStarsCanvasRef} style={{ display: 'none' }} />
                <Intro backgroundImage={backgroundImage} sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} />
            </Element>
            <Element>
                <Footer />
            </Element>
            <SigninMethodsWidget sessionId={sessionId} />
        </div>
    );
}

export default HomePage;